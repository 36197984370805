<template>
  <div>
    <question-header
      :title="question.title"
      :description="question.description"
      :help="question.help">
    </question-header>

    <b-form action="" @submit.prevent="submit">
      <b-form-group
        class="mb-4"
        :invalid-feedback="question.invalid"
        :state="$v.text.$invalid ? false : null"
        >
        <b-input-group size="lg">
          <b-input-group-prepend v-if="question.icon && question.prepend" is-text>
            <b-icon :icon="question.icon"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="text-input"
            ref="textInput"
            type="text"
            :placeholder="question.placeholder"
            :state="$v.text.$invalid ? false : null"
            v-model="$v.text.$model"
            autofocus
          />
          <b-input-group-append v-if="question.icon && !question.prepend" is-text>
            <b-icon :icon="question.icon"></b-icon>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <question-footer
        @back="back"
        @next="submitAnswer"
        v-bind:v="$v"
      />
    </b-form>
  </div>
</template>

<script>
import QuestionType from '@/inc/QuestionType'
import { required, minValue, between, integer } from 'vuelidate/lib/validators'

import QuestionHeader from '@/components/QuestionHeader.vue'
import QuestionFooter from '@/components/QuestionFooter.vue'

export default {
  extends: QuestionType,
  components: {
    QuestionHeader,
    QuestionFooter
  },
  data () {
    return {
      text: null,
    }
  },
  mounted () {
    if (this.currentAnswer) {
      this.text = this.currentAnswer
    }
  },
  validations() {
    if (this.question.validation === 'positive') {
      return {
        text: {
          required,
          integer,
          minValue : minValue(0)
        }
      }
    }
    if (this.question.validation === 'positive-3') {
      return {
        text: {
          required,
          integer,
          between : between(0, 100)
        }
      }
    }
    if (this.question.validation === 'year') {
      return {
        text: {
          required,
          integer,
          between : between(1800, new Date().getFullYear())
        }
      }
    }
    if (this.question.validation === 'money') {
      return {
        text: {
          required,
          integer
        }
      }
    }
    if (this.question.validation === 'money-positive') {
      return {
        text: {
          required,
          integer,
          minValue : minValue(0)
        }
      }
    }

    // default: no specific validator is given
    return {
      text: {
        required
      }
    }
  },
  methods: {
    submitAnswer () {
      this.answer(this.text)
    }
  }
}
</script>
