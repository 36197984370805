import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import questions from './questions'

Vue.use(Vuex)

const vuexkey = 'kmu.vuex'

const vuexLocalStorage = new VuexPersist({
  key: vuexkey,
  storage: window.localStorage,
})

const defaultState = () => {
  return {
    nutzungbedingungen: false,
    questionaireType: null,
    questionaireCase: null,
    valuation : {
      flow : null,
      case: null,
      report: null,
      value: null
    },
    upgrade: {
      email: null,
      requested: false
    }
  }
}

// Create a new store instance.
const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [vuexLocalStorage.plugin],
  state: defaultState(),

  mutations: {
    setNutzungsbedingungen(state, value) {
      state.nutzungbedingungen = value;
    },
    setQuestionaireType(state, value) {
      state.questionaireType = value
    },
    setQuestionaireCase(state, value) {
      state.questionaireCase = value
    },
    setValuation(state, valuation) {
      state.valuation = valuation
    },
    resetValuation(state) {
      state.valuation = {
        flow : null,
        case: null,
        report: null,
        value: null
      }
    },
    setUpgrade(state, upgrade) {
      state.upgrade = upgrade
    },
    resetUpgrade(state) {
      state.upgrade = {
        email: null,
        requested: false
      }
    },
    resetStorage(state) {
      console.log('flushing cache ...');
      Object.assign(state, defaultState())
      window.localStorage.removeItem(this.vuexkey);
    }
  },

  modules: {
    questions
  }
})

export default store
