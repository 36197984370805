<template>
  <div>
    <question-header
      :title="question.title"
      :description="question.description"
      :help="question.help">
    </question-header>

    <b-form action="" @submit.prevent="submitAnswer">
      <b-form-group class="mb-4">
        <b-input-group size="lg">
          <b-input-group-prepend is-text>
            <b-icon icon="envelope"></b-icon>
          </b-input-group-prepend>
          <b-form-input type="email" autofocus="autofocus" v-model="email" placeholder="ich@firma.de"/>
        </b-input-group>
      </b-form-group>
      <b-form-group class="mb-4">
        <b-input-group size="lg">
          <b-input-group-prepend is-text>
            <b-icon icon="envelope"></b-icon>
          </b-input-group-prepend>
          <b-form-input type="email" v-model="confirmation" placeholder="ich@firma.de"/>
        </b-input-group>
      </b-form-group>

      <question-footer
        @back="back"
        @next="submitAnswer"
      />

    </b-form>
  </div>
</template>

<script>
import QuestionType from '@/inc/QuestionType'

import QuestionHeader from '@/components/QuestionHeader.vue'

export default {
  extends: QuestionType,
  components: {
    QuestionHeader
  },
  data () {
    return {
      email: null,
      confirmation: null
    }
  },
  mounted () {
    if (this.currentAnswer) {
      this.email = this.currentAnswer
    }
  },
  methods: {
    submitAnswer () {
      this.answer(this.email)
    }
  }
}
</script>
