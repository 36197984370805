<template>

  <div>
    <question-header
      :title="question.title"
      :description="question.description"
      :help="question.help">
    </question-header>

    <b-form action="" v-on:submit.prevent="submitAnswer">

      <div>
        <b-card no-body>
          <b-tabs card>
            <b-tab disabled>
              <template #title>
                <h6>1. Gewinn- und Verlustrechnung &#x2192;</h6>
              </template>
            </b-tab>
            <b-tab>
              <template #title>
                <h6>2. Korrekturen &#x2192;</h6>
              </template>
              <b-card-body>
                <b-card-text class="mb-4">
                  <p>Das Ertragswertverfahren ermittelt den Unternehmenswert durch Diskontierung der den Unternehmenseignern zukünftig zufließenden finanziellen Überschüsse, die aus den Vergangenheitswerten abgeleitet werden.</p>
                  <p>Eine Bereinigung / Korrektur der Vergangenheitserfolgsrechnung kann unter Umständen notwendig sein. Ihre Angaben bei den folgenden Abfragen helfen dabei.</p>
                </b-card-text>
                <b-table-simple hover responsive>
                  <b-thead>
                      <b-tr variant="light">
                        <b-th>Werte in €</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                      </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>Geschäftsführergehalt / Unternehmerlohn</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input id="personalaufwand_gf_1" v-model="unternehmensdaten.personalaufwand_gf_1" :s="workflow.stepCompleted" :v="$v.unternehmensdaten.personalaufwand_gf_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input id="personalaufwand_gf_2" v-model="unternehmensdaten.personalaufwand_gf_2" :s="workflow.stepCompleted" :v="$v.unternehmensdaten.personalaufwand_gf_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input id="personalaufwand_gf_3" v-model="unternehmensdaten.personalaufwand_gf_3" :s="workflow.stepCompleted" :v="$v.unternehmensdaten.personalaufwand_gf_3" />
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>

                <div class="kmu-financialdata--info-container">
                  <b-card-text>
                    <p>Sofern in Ihrer GuV im Personalaufwand ein Geschäftsführergehalt enthalten ist, geben Sie dies bitte hier an.</p>
                    <p>Hinweis: Bitte geben Sie hier nur ein GF-Gehalt an, wenn dies Teil der Personalkosten in Ihrer GuV ist. Bei Personengesellschaften ist das GF-Gehalt in der Regel <strong>nicht</strong> im Personalaufwand enthalten. Dann tragen Sie hier bitte 0 ein.</p>
                  </b-card-text>
                </div>

                <b-table-simple hover responsive>
                  <b-thead>
                      <b-tr variant="light">
                        <b-th>Werte in €</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                      </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>Marktübliches Geschäftsführergehalt</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input id="personalaufwand_gf_markt_1" v-model="unternehmensdaten.personalaufwand_gf_markt_1" :v="$v.unternehmensdaten.personalaufwand_gf_markt_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input id="personalaufwand_gf_markt_2" v-model="unternehmensdaten.personalaufwand_gf_markt_2" :v="$v.unternehmensdaten.personalaufwand_gf_markt_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input id="personalaufwand_gf_markt_3" v-model="unternehmensdaten.personalaufwand_gf_markt_3" :v="$v.unternehmensdaten.personalaufwand_gf_markt_3" />
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>

                <div class="kmu-financialdata--info-container">
                  <b-card-text>
                    <p>Bitte geben Sie hier das aus Ihrer Sicht marktübliche Geschäftsführergehalt an. Als Referenz dient Ihnen die Tabelle <a href="https://www.kmu-value.de/glossar-detail/unternehmerlohn.html" target="_blank">„Geschäftsführergehälter / Kalkulatorischer Unternehmerlohn“</a> aus unserem Bereich <a href="https://www.kmu-value.de/wissen-unternehmensbewertung.html" target="_blank">Wissen Unternehmensbewertung</a> zur Orientierung.</p>
                    <p>Bei Personengesellschaften werden die von Ihnen gemachten Eingaben als kalkulatorischer Unternehmerlohn im Personalaufwand berücksichtigt.</p>
                    <p>Bei Kapitalgesellschaften wird eine etwaige Differenz zwischen im Personalaufwand enthaltenen Geschäftsführergehalt und marktüblichem Geschäftsführergehalt im Personalaufwand bereinigt.</p>
                  </b-card-text>
                </div>
                <b-table-simple hover>
                  <b-thead>
                      <b-tr variant="light">
                        <b-th>Werte in €</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                      </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>Zusätzliche ergebnisbeeinflussende Effekte</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input id="sonstige_effekte_1" v-model="unternehmensdaten.sonstige_effekte_1" :v="$v.unternehmensdaten.sonstige_effekte_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input id="sonstige_effekte_2" v-model="unternehmensdaten.sonstige_effekte_2" :v="$v.unternehmensdaten.sonstige_effekte_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input id="sonstige_effekte_3" v-model="unternehmensdaten.sonstige_effekte_3" :v="$v.unternehmensdaten.sonstige_effekte_3" />
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>

                <div class="kmu-financialdata--info-container">
                  <b-card-text>
                    <p>Bitte geben Sie hier weitere Korrekturen zur GuV ein. Einmaleffekte, die im Erfassungszeitraum in Ihrer GuV enthalten sind, sind möglichst zu bereinigen und hier einzutragen.</p>
                    <p>Einmalige Erträge, z.B. Versicherungsentschädigungen, sind mit positivem Vorzeichen (+) und einmalige Aufwendungen, z.B. Forderungsverluste, mit negativem Vorzeichen (-) zu erfassen.</p>
                  </b-card-text>
                </div>


                <div class="kmu-financialdata--workflow-container">
                  <b-form-checkbox v-model="workflow.stepCompleted" :v="$v.workflow.stepCompleted">
                    Meine Eingaben für den Bereich <strong>Korrekturen</strong> sind vollständig.
                  </b-form-checkbox>
                </div>
              </b-card-body>
            </b-tab>
            <b-tab disabled>
              <template #title>
                <h6>3. Bilanz (ergänzend)</h6>
              </template>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>

      <div v-if="workflow.stepCompleted && $v.$invalid" class="kmu-financialdata--error-container validation-errors">
        <label><strong>Bei der Validierung Ihrer Eingaben sind uns Unstimmigkeiten aufgefallen!</strong></label>
        <ul>
          <li>Sie haben Personalaufwände erfaßt, aber weder das Geschäftsführergehalt spezifiziert noch ein marktübliches Geschäftsführergehalt angegeben. Wechseln Sie in den Bereich <b-link href="#" @click="workflow.activeStep = 1">Korrekturen</b-link> um diese Daten zu erfassen.</li>
        </ul>
      </div>

      <question-footer
        @back="submitBack"
        @next="submitAnswer"
        v-bind:v="$v"
      />

    </b-form>

  </div>
</template>

<script>
import QuestionType from '@/inc/QuestionType'
import QuestionHeader from '@/components/QuestionHeader.vue'
import QuestionFooter from '@/components/QuestionFooter.vue'
import FinancialDataInput from '@/components/FinancialData/FinancialDataInput.vue'

import { helpers, required, integer, minValue } from 'vuelidate/lib/validators'

import { mapState } from 'vuex'

const almostEqual = (compareToField1, compareToField2) =>
  helpers.withParams(
    { type: 'almostEqual', compareToField1, compareToField2 },
    function(value, parentVm) {

      let num1 = Math.abs(parseFloat(value))
      let num2 = Math.abs(parseFloat(helpers.ref(compareToField1, this, parentVm)))
      let num3 = Math.abs(parseFloat(helpers.ref(compareToField2, this, parentVm)))

      let values = [num1, num2, num3].sort( function(a,b) { return a - b; } );

      let median = 0
      let biggest = values[values.length-1]

      // calculate median value to identify possible outbreaks ...
      let half = Math.floor(values.length/2);
      if (values.length % 2)
          median = values[half];
      else
          median = (values[half-1] + values[half]) / 2.0;

      // epsilon is assumend to be 10-times bigger than the
      // median - which is definitely subject to adjust
      let epsilon = median * 10

      // FIXME: essentially switch off validato
      epsilon = 0

      // if epsilon is equal to 0 we do assume columns are not (yet) filled
      if ( epsilon == 0 ) {
        return true
      } else {
        return biggest <= epsilon
      }
    }
  )

/*
const lessEqualThan = (compareToField1, compareToField2) =>
  helpers.withParams(
    { type: 'lessEqualThan', compareToField1, compareToField2 },
    function(value, parentVm) {

      let target = Math.abs(parseFloat(value))
      let num1 = Math.abs(parseFloat(helpers.ref(compareToField1, this, parentVm)))
      let num2 = Math.abs(parseFloat(helpers.ref(compareToField2, this, parentVm)))

      let sum = 0
        sum = sum + ( isNaN(num1) ? 0 : num1 )
        sum = sum + ( isNaN(num2) ? 0 : num2 )

      return target <= sum
    }
  )
  */

export default {
  extends: QuestionType,

  components: {
    QuestionHeader,
    FinancialDataInput,
    QuestionFooter
  },

  data () {
    return {
      workflow: {
        stepCompleted: false
      },
      unternehmensdaten: {
        personalaufwand_gf_1: 0,
        personalaufwand_gf_2: 0,
        personalaufwand_gf_3: 0,
        personalaufwand_gf_markt_1: 0,
        personalaufwand_gf_markt_2: 0,
        personalaufwand_gf_markt_3: 0,
        sonstige_effekte_1: 0,
        sonstige_effekte_2: 0,
        sonstige_effekte_3: 0
      }
    }
  },
  mounted() {
    if (this.currentAnswer) {
      this.unternehmensdaten = Object.assign({}, this.unternehmensdaten, this.currentAnswer)
      this.unternehmensdaten_guv = Object.assign({}, this.answers.unternehmensdaten_guv)
    }
  },
  validations() {
    return {
      workflow: {
        stepCompleted: { checked: value => value === true },
      },
      unternehmensdaten: {
        sonstige_effekte_1: { required, integer, almostEqual: almostEqual('sonstige_effekte_2', 'sonstige_effekte_3') },
        sonstige_effekte_2: { required, integer, almostEqual: almostEqual('sonstige_effekte_1', 'sonstige_effekte_3') },
        sonstige_effekte_3: { required, integer, almostEqual: almostEqual('sonstige_effekte_1', 'sonstige_effekte_2') },
        personalaufwand_gf_1: {
          required,
          integer,
          almostEqual: almostEqual('personalaufwand_gf_2', 'personalaufwand_gf_3'),
          korrekturWennAufwand: function (value) {
            return this.answers.unternehmensdaten_guv.personalaufwand_1 == 0 || (this.answers.unternehmensdaten_guv.personalaufwand_1 > 0 && (this.unternehmensdaten.personalaufwand_gf_markt_1 + value > 0))
          },
          korrekturGroesserAlsAufwand: function (value) {
            return value <= this.answers.unternehmensdaten_guv.personalaufwand_1;
          }
        },
        personalaufwand_gf_2: {
          required,
          integer,
          almostEqual: almostEqual('personalaufwand_gf_1', 'personalaufwand_gf_3'),
          korrekturWennAufwand: function (value) {
            return this.answers.unternehmensdaten_guv.personalaufwand_2 == 0 || (this.answers.unternehmensdaten_guv.personalaufwand_2 > 0 && (this.unternehmensdaten.personalaufwand_gf_markt_2 + value > 0))
          },
          korrekturGroesserAlsAufwand: function (value) {
            return value <= this.answers.unternehmensdaten_guv.personalaufwand_2;
          }
        },
        personalaufwand_gf_3: {
          required,
          integer,
          almostEqual: almostEqual('personalaufwand_gf_1', 'personalaufwand_gf_2'),
          korrekturWennAufwand: function (value) {
            return this.answers.unternehmensdaten_guv.personalaufwand_3 == 0 || (this.answers.unternehmensdaten_guv.personalaufwand_3 > 0 && (this.unternehmensdaten.personalaufwand_gf_markt_3 + value > 0))
          },
          korrekturGroesserAlsAufwand: function (value) {
            return value <= this.answers.unternehmensdaten_guv.personalaufwand_3;
          }
        },
        personalaufwand_gf_markt_1: {
          required,
          integer,
          almostEqual:
          almostEqual('personalaufwand_gf_markt_2', 'personalaufwand_gf_markt_3'),
          minGehalt: minValue(1)
        },
        personalaufwand_gf_markt_2: {
          required,
          integer,
          almostEqual:
          almostEqual('personalaufwand_gf_markt_1', 'personalaufwand_gf_markt_3'),
          minGehalt: minValue(1)
        },
        personalaufwand_gf_markt_3: {
          required,
          integer,
          almostEqual:
          almostEqual('personalaufwand_gf_markt_1', 'personalaufwand_gf_markt_2'),
          minGehalt: minValue(1)
        },
      },
      sonstigeEffekteGroup: [ 'unternehmensdaten.sonstige_effekte_3', 'unternehmensdaten.sonstige_effekte_2', 'unternehmensdaten.sonstige_effekte_1' ],
      personalaufwandGfGroup: [ 'unternehmensdaten.personalaufwand_gf_3', 'unternehmensdaten.personalaufwand_gf_2', 'unternehmensdaten.personalaufwand_gf_1' ],
      personalaufwandGfMarktGroup: [ 'unternehmensdaten.personalaufwand_gf_markt_3', 'unternehmensdaten.personalaufwand_gf_markt_2', 'unternehmensdaten.personalaufwand_gf_markt_1' ],
    }
  },
  computed: {
    betrachtungsjahre() {
      const currentYear = new Date().getFullYear()
      const b = []

      b.istjahr_3 = currentYear - 1
      b.istjahr_2 = currentYear - 2
      b.istjahr_1 = currentYear - 3

      return b
    },
    ...mapState('questions', {
      answers: state => state.answers
    }),
  },
  methods: {
    submitBack () {
      this.back(this.unternehmensdaten)
    },
    submitAnswer () {
      this.answer(this.unternehmensdaten)
    }
  }
}
</script>

<style lang="scss" scoped>

.kmu-financialdata--workflow-container {
  box-shadow: 0 4px 8px 0 rgba(17,22,26,.16),0 4px 8px 0 rgba(17,22,26,.08),0 8px 16px 0 rgba(17,22,26,.04);
  background-color: #e9e9e9;
  display: flex;
  padding: 1rem;
  font-style: italic;
  margin-top: 2rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.kmu-financialdata--info-container {
  //box-shadow: 0 4px 8px 0 rgba(17,22,26,.16),0 4px 8px 0 rgba(17,22,26,.08),0 8px 16px 0 rgba(17,22,26,.04);
  background-color: #E6F9FE;
  display: flex;
  padding: 1.5rem;
  font-style: italic;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
  margin-bottom: 2rem;

  p:last-child {
    margin-bottom: 0;
  }
}

.kmu-financialdata--error-container {
  margin-top: 3rem;
  margin-left: 3.5rem;
  margin-right: 3.25rem;
}

.validation-errors {
  color: red;
}

</style>
