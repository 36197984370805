<template>

  <div>
    <question-header
      :title="question.title"
      :description="question.description"
      :help="question.help">
    </question-header>

    <b-form action="" v-on:submit.prevent="submitAnswer">

      <div>
        <b-card no-body>
          <b-tabs card>
            <b-tab disabled>
              <template #title>
                <h6>1. Gewinn- und Verlustrechnung &#x2192;</h6>
              </template>
            </b-tab>
            <b-tab disabled>
              <template #title>
                <h6>2. Korrekturen &#x2192;</h6>
              </template>
            </b-tab>
            <b-tab>
              <template #title>
                <h6>3. Bilanz (ergänzend)</h6>
              </template>
              <b-card-body>
                <b-card-text class="mb-4">
                  <p>Für den Fall, dass für das Unternehmen keine Bilanzen vorliegen bzw. erstellt werden, ist darauf hinzuweisen, dass eine fehlende Bilanzierung zu Unschärfen innerhalb der Substanzwertermittlung sowie der Unternehmensbewertung führen.</p><p>Für ein möglichst genaues Bewertungsresultat empfehlen wir möglichst eine ganzheitliche Erfassung vorzunehmen und ggfs. einzelne Bilanzpositionen zu schätzen oder aus einer vorliegenden Summen- und Saldenliste herzuleiten (<em>für nicht vorhandene Bilanzpositionen ist der Wert „0“ zu erfassen.</em>).</p>
                </b-card-text>
                <b-table-simple hover responsive>
                  <b-thead>
                      <b-tr variant="light">
                        <b-th>Aktiva in €</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                      </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>Grundstücke und Gebäude</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.grundstuecke_1" :v="$v.unternehmensdaten.grundstuecke_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.grundstuecke_2" :v="$v.unternehmensdaten.grundstuecke_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.grundstuecke_3" :v="$v.unternehmensdaten.grundstuecke_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Technische Anlagen und Maschinen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.maschinen_1" :v="$v.unternehmensdaten.maschinen_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.maschinen_2" :v="$v.unternehmensdaten.maschinen_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.maschinen_3" :v="$v.unternehmensdaten.maschinen_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Betriebs- und Geschäftsausstattung</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.geschaeftsausstattung_1" :v="$v.unternehmensdaten.geschaeftsausstattung_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.geschaeftsausstattung_2" :v="$v.unternehmensdaten.geschaeftsausstattung_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.geschaeftsausstattung_3" :v="$v.unternehmensdaten.geschaeftsausstattung_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Geleistete Anzahlungen und Anlagen im Bau</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.anlagen_im_bau_1" :v="$v.unternehmensdaten.anlagen_im_bau_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.anlagen_im_bau_2" :v="$v.unternehmensdaten.anlagen_im_bau_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.anlagen_im_bau_3" :v="$v.unternehmensdaten.anlagen_im_bau_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Weiteres Anlagevermögen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.weiteres_av_1" :v="$v.unternehmensdaten.weiteres_av_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.weiteres_av_2" :v="$v.unternehmensdaten.weiteres_av_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.weiteres_av_3" :v="$v.unternehmensdaten.weiteres_av_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Kassenbestand</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.kassenbestand_1" :v="$v.unternehmensdaten.kassenbestand_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.kassenbestand_2" :v="$v.unternehmensdaten.kassenbestand_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.kassenbestand_3" :v="$v.unternehmensdaten.kassenbestand_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Vorräte</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.vorraete_1" :v="$v.unternehmensdaten.vorraete_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.vorraete_2" :v="$v.unternehmensdaten.vorraete_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.vorraete_3" :v="$v.unternehmensdaten.vorraete_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Forderungen und sonstige Vermögensgegenstände</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.forderungen_sonstige_vg_1" :v="$v.unternehmensdaten.forderungen_sonstige_vg_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.forderungen_sonstige_vg_2" :v="$v.unternehmensdaten.forderungen_sonstige_vg_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.forderungen_sonstige_vg_3" :v="$v.unternehmensdaten.forderungen_sonstige_vg_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Weiteres Umlaufvermögen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.umlaufvermoegen_1" :v="$v.unternehmensdaten.umlaufvermoegen_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.umlaufvermoegen_2" :v="$v.unternehmensdaten.umlaufvermoegen_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.umlaufvermoegen_3" :v="$v.unternehmensdaten.umlaufvermoegen_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Sonstige Aktiva / Rechnungsabgrenzungsposten</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_aktiva_1" :v="$v.unternehmensdaten.sonstige_aktiva_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_aktiva_2" :v="$v.unternehmensdaten.sonstige_aktiva_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_aktiva_3" :v="$v.unternehmensdaten.sonstige_aktiva_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>Summe Aktiva</b-th>
                      <b-td style="width: 10em">
                        <financial-data-field v-model="unternehmensdaten.summeAktiva_1" :v="$v.unternehmensdaten.summeAktiva_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-field v-model="unternehmensdaten.summeAktiva_2" :v="$v.unternehmensdaten.summeAktiva_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-field v-model="unternehmensdaten.summeAktiva_3" :v="$v.unternehmensdaten.summeAktiva_3" />
                      </b-td>
                    </b-tr>
                    <b-tr variant="light">
                      <b-th>Passiva in €</b-th>
                      <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                      <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                      <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td>Eigenkapital</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.eigenkapital_1" :v="$v.unternehmensdaten.eigenkapital_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.eigenkapital_2" :v="$v.unternehmensdaten.eigenkapital_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.eigenkapital_3" :v="$v.unternehmensdaten.eigenkapital_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Rückstellungen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.rueckstellungen_1" :v="$v.unternehmensdaten.rueckstellungen_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.rueckstellungen_2" :v="$v.unternehmensdaten.rueckstellungen_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.rueckstellungen_3" :v="$v.unternehmensdaten.rueckstellungen_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Verbindlichkeiten</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.verbindlichkeiten_1" :v="$v.unternehmensdaten.verbindlichkeiten_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.verbindlichkeiten_2" :v="$v.unternehmensdaten.verbindlichkeiten_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.verbindlichkeiten_3" :v="$v.unternehmensdaten.verbindlichkeiten_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td><em>davon verzinsliches Fremdkapital</em></b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.fremdkapital_1" :v="$v.unternehmensdaten.fremdkapital_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.fremdkapital_2" :v="$v.unternehmensdaten.fremdkapital_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.fremdkapital_3" :v="$v.unternehmensdaten.fremdkapital_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Sonstige Passiva / Rechnungsabgrenzungsposten</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_passiva_1" :v="$v.unternehmensdaten.sonstige_passiva_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_passiva_2" :v="$v.unternehmensdaten.sonstige_passiva_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_passiva_3" :v="$v.unternehmensdaten.sonstige_passiva_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>Summe Passiva</b-th>
                      <b-td style="width: 10em">
                        <financial-data-field v-model="unternehmensdaten.summePassiva_1" :v="$v.unternehmensdaten.summePassiva_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-field v-model="unternehmensdaten.summePassiva_2" :v="$v.unternehmensdaten.summePassiva_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-field v-model="unternehmensdaten.summePassiva_3" :v="$v.unternehmensdaten.summePassiva_3" />
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <div class="kmu-financialdata--workflow-container">
                  <b-form-checkbox v-model="workflow.stepCompleted">
                    Meine Eingaben für den Bereich <strong>Bilanz</strong> sind vollständig.
                  </b-form-checkbox>
                </div>
              </b-card-body>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>

      <div v-if="false" class="kmu-financialdata--error-container validation-errors">
        <label><strong>Bei der Validierung Ihrer Eingaben sind uns Unstimmigkeiten aufgefallen!</strong></label>
        <ul>
          <li>Sie haben Personalaufwände erfaßt, aber weder das Geschäftsführergehalt spezifiziert noch ein marktübliches Geschäftsführergehalt angegeben. Wechseln Sie in den Bereich <b-link href="#" @click="workflow.activeStep = 1">Korrekturen</b-link> um diese Daten zu erfassen.</li>
        </ul>
      </div>

      <question-footer
        @back="submitBack"
        @next="submitAnswer"
        v-bind:v="$v"
      />

    </b-form>

  </div>
</template>

<script>
import QuestionType from '@/inc/QuestionType'
import QuestionHeader from '@/components/QuestionHeader.vue'
import QuestionFooter from '@/components/QuestionFooter.vue'
import FinancialDataInput from '@/components/FinancialData/FinancialDataInput.vue'
import FinancialDataField from '@/components/FinancialData/FinancialDataField.vue'

import { helpers, required, integer } from 'vuelidate/lib/validators'

const equal = (compareTo) =>
  helpers.withParams(
    { type: 'equal', compareTo },
    function(value) {

      let num1 = Math.abs(parseFloat(value))
      let num2 = Math.abs(parseFloat(compareTo))

      console.log('num1: ' + num1)
      console.log('num2: ' + num2)
      console.log('equal: ' + num1 === num2)

      return num1 === num2
    }
  )

const almostEqual = (compareToField1, compareToField2) =>
  helpers.withParams(
    { type: 'almostEqual', compareToField1, compareToField2 },
    function(value, parentVm) {

      let num1 = Math.abs(parseFloat(value))
      let num2 = Math.abs(parseFloat(helpers.ref(compareToField1, this, parentVm)))
      let num3 = Math.abs(parseFloat(helpers.ref(compareToField2, this, parentVm)))

      let values = [num1, num2, num3].sort( function(a,b) { return a - b; } );

      let median = 0
      let biggest = values[values.length-1]

      // calculate median value to identify possible outbreaks ...
      let half = Math.floor(values.length/2);
      if (values.length % 2)
          median = values[half];
      else
          median = (values[half-1] + values[half]) / 2.0;

      // epsilon is assumend to be 10-times bigger than the
      // median - which is definitely subject to adjust
      let epsilon = median * 10

      // FIXME: essentially switch off validato
      epsilon = 0

      // if epsilon is equal to 0 we do assume columns are not (yet) filled
      if ( epsilon == 0 ) {
        return true
      } else {
        return biggest <= epsilon
      }
    }
  )

export default {
  extends: QuestionType,

  components: {
    QuestionHeader,
    FinancialDataInput,
    FinancialDataField,
    QuestionFooter
  },

  data () {
    return {
      workflow: {
        stepCompleted: false
      },
      unternehmensdaten: {
        grundstuecke_1: 0,
        grundstuecke_2: 0,
        grundstuecke_3: 0,
        maschinen_1: 0,
        maschinen_2: 0,
        maschinen_3: 0,
        geschaeftsausstattung_1: 0,
        geschaeftsausstattung_2: 0,
        geschaeftsausstattung_3: 0,
        anlagen_im_bau_1: 0,
        anlagen_im_bau_2: 0,
        anlagen_im_bau_3: 0,
        weiteres_av_1: 0,
        weiteres_av_2: 0,
        weiteres_av_3: 0,
        kassenbestand_1: 0,
        kassenbestand_2: 0,
        kassenbestand_3: 0,
        vorraete_1: 0,
        vorraete_2: 0,
        vorraete_3: 0,
        forderungen_sonstige_vg_1: 0,
        forderungen_sonstige_vg_2: 0,
        forderungen_sonstige_vg_3: 0,
        umlaufvermoegen_1: 0,
        umlaufvermoegen_2: 0,
        umlaufvermoegen_3: 0,
        sonstige_aktiva_1: 0,
        sonstige_aktiva_2: 0,
        sonstige_aktiva_3: 0,
        eigenkapital_1: 0,
        eigenkapital_2: 0,
        eigenkapital_3: 0,
        rueckstellungen_1: 0,
        rueckstellungen_2: 0,
        rueckstellungen_3: 0,
        verbindlichkeiten_1: 0,
        verbindlichkeiten_2: 0,
        verbindlichkeiten_3: 0,
        fremdkapital_1: 0,
        fremdkapital_2: 0,
        fremdkapital_3: 0,
        sonstige_passiva_1: 0,
        sonstige_passiva_2: 0,
        sonstige_passiva_3: 0,
        summeAktiva_1: 0,
        summeAktiva_2: 0,
        summeAktiva_3: 0,
        summePassiva_1: 0,
        summePassiva_2: 0,
        summePassiva_3: 0,
      }
    }
  },
  mounted() {
    if (this.currentAnswer) {
      this.unternehmensdaten = Object.assign({}, this.unternehmensdaten, this.currentAnswer)
    }
  },
  validations() {
    return {
      workflow: {
        stepCompleted: { checked: value => value === true }
      },
      unternehmensdaten: {
        grundstuecke_1: { required, integer, almostEqual: almostEqual('grundstuecke_2', 'grundstuecke_3') },
        grundstuecke_2: { required, integer, almostEqual: almostEqual('grundstuecke_1', 'grundstuecke_3') },
        grundstuecke_3: { required, integer, almostEqual: almostEqual('grundstuecke_1', 'grundstuecke_2') },
        maschinen_1: { required, integer, almostEqual: almostEqual('maschinen_2', 'maschinen_3') },
        maschinen_2: { required, integer, almostEqual: almostEqual('maschinen_1', 'maschinen_3') },
        maschinen_3: { required, integer, almostEqual: almostEqual('maschinen_1', 'maschinen_2') },
        geschaeftsausstattung_1: { required, integer, almostEqual: almostEqual('geschaeftsausstattung_2', 'geschaeftsausstattung_3') },
        geschaeftsausstattung_2: { required, integer, almostEqual: almostEqual('geschaeftsausstattung_1', 'geschaeftsausstattung_3') },
        geschaeftsausstattung_3: { required, integer, almostEqual: almostEqual('geschaeftsausstattung_1', 'geschaeftsausstattung_2') },
        anlagen_im_bau_1: { required, integer, almostEqual: almostEqual('anlagen_im_bau_2', 'anlagen_im_bau_3') },
        anlagen_im_bau_2: { required, integer, almostEqual: almostEqual('anlagen_im_bau_1', 'anlagen_im_bau_3') },
        anlagen_im_bau_3: { required, integer, almostEqual: almostEqual('anlagen_im_bau_1', 'anlagen_im_bau_2') },
        weiteres_av_1: { required, integer, almostEqual: almostEqual('weiteres_av_2', 'weiteres_av_3') },
        weiteres_av_2: { required, integer, almostEqual: almostEqual('weiteres_av_1', 'weiteres_av_3') },
        weiteres_av_3: { required, integer, almostEqual: almostEqual('weiteres_av_1', 'weiteres_av_2') },
        kassenbestand_1: { required, integer, almostEqual: almostEqual('kassenbestand_2', 'kassenbestand_3') },
        kassenbestand_2: { required, integer, almostEqual: almostEqual('kassenbestand_1', 'kassenbestand_3') },
        kassenbestand_3: { required, integer, almostEqual: almostEqual('kassenbestand_1', 'kassenbestand_2') },
        vorraete_1: { required, integer, almostEqual: almostEqual('bestandsveraenderung_1', 'bestandsveraenderung_2') },
        vorraete_2: { required, integer, almostEqual: almostEqual('bestandsveraenderung_1', 'bestandsveraenderung_2') },
        vorraete_3: { required, integer, almostEqual: almostEqual('bestandsveraenderung_1', 'bestandsveraenderung_2') },
        forderungen_sonstige_vg_1: { required, integer, almostEqual: almostEqual('forderungen_sonstige_vg_2', 'forderungen_sonstige_vg_3') },
        forderungen_sonstige_vg_2: { required, integer, almostEqual: almostEqual('forderungen_sonstige_vg_1', 'forderungen_sonstige_vg_3') },
        forderungen_sonstige_vg_3: { required, integer, almostEqual: almostEqual('forderungen_sonstige_vg_1', 'forderungen_sonstige_vg_2') },
        umlaufvermoegen_1: { required, integer, almostEqual: almostEqual('umlaufvermoegen_2', 'umlaufvermoegen_3') },
        umlaufvermoegen_2: { required, integer, almostEqual: almostEqual('umlaufvermoegen_1', 'umlaufvermoegen_3') },
        umlaufvermoegen_3: { required, integer, almostEqual: almostEqual('umlaufvermoegen_1', 'umlaufvermoegen_2') },
        sonstige_aktiva_1: { required, integer, almostEqual: almostEqual('sonstige_aktiva_2', 'sonstige_aktiva_3') },
        sonstige_aktiva_2: { required, integer, almostEqual: almostEqual('sonstige_aktiva_1', 'sonstige_aktiva_3') },
        sonstige_aktiva_3: { required, integer, almostEqual: almostEqual('sonstige_aktiva_1', 'sonstige_aktiva_2') },
        summeAktiva_1: { summeAktivaPassiva: equal(this.unternehmensdaten.summePassiva_1) },
        summeAktiva_2: { summeAktivaPassiva: equal(this.unternehmensdaten.summePassiva_2) },
        summeAktiva_3: { summeAktivaPassiva: equal(this.unternehmensdaten.summePassiva_3) },
        eigenkapital_1: { required, integer, almostEqual: almostEqual('eigenkapital_2', 'eigenkapital_3') },
        eigenkapital_2: { required, integer, almostEqual: almostEqual('eigenkapital_1', 'eigenkapital_3') },
        eigenkapital_3: { required, integer, almostEqual: almostEqual('eigenkapital_1', 'eigenkapital_2') },
        rueckstellungen_1: { required, integer, almostEqual: almostEqual('rueckstellungen_2', 'rueckstellungen_3') },
        rueckstellungen_2: { required, integer, almostEqual: almostEqual('rueckstellungen_1', 'rueckstellungen_3') },
        rueckstellungen_3: { required, integer, almostEqual: almostEqual('rueckstellungen_1', 'rueckstellungen_2') },
        verbindlichkeiten_1: { required, integer, almostEqual: almostEqual('verbindlichkeiten_2', 'verbindlichkeiten_3') },
        verbindlichkeiten_2: { required, integer, almostEqual: almostEqual('verbindlichkeiten_1', 'verbindlichkeiten_3') },
        verbindlichkeiten_3: { required, integer, almostEqual: almostEqual('verbindlichkeiten_1', 'verbindlichkeiten_2') },
        fremdkapital_1: { required, integer, almostEqual: almostEqual('fremdkapital_2', 'fremdkapital_3') },
        fremdkapital_2: { required, integer, almostEqual: almostEqual('fremdkapital_1', 'fremdkapital_3') },
        fremdkapital_3: { required, integer, almostEqual: almostEqual('fremdkapital_1', 'fremdkapital_2') },
        sonstige_passiva_1: { required, integer, almostEqual: almostEqual('sonstige_passiva_2', 'sonstige_passiva_3') },
        sonstige_passiva_2: { required, integer, almostEqual: almostEqual('sonstige_passiva_1', 'sonstige_passiva_3') },
        sonstige_passiva_3: { required, integer, almostEqual: almostEqual('sonstige_passiva_1', 'sonstige_passiva_2') },
        summePassiva_1: { summeAktivaPassiva: equal(this.unternehmensdaten.summeAktiva_1) },
        summePassiva_2: { summeAktivaPassiva: equal(this.unternehmensdaten.summeAktiva_2) },
        summePassiva_3: { summeAktivaPassiva: equal(this.unternehmensdaten.summeAktiva_3) },
      },
      grundstueckeGroup: [ 'unternehmensdaten.grundstuecke_3', 'unternehmensdaten.grundstuecke_2', 'unternehmensdaten.grundstuecke_1' ],
      maschinenGroup: [ 'unternehmensdaten.maschinen_3', 'unternehmensdaten.maschinen_2', 'unternehmensdaten.maschinen_1' ],
      geschaeftsausstattungGroup: [ 'unternehmensdaten.geschaeftsausstattung_3', 'unternehmensdaten.geschaeftsausstattung_2', 'unternehmensdaten.geschaeftsausstattung_1' ],
      anlagenImBauGroup: [ 'unternehmensdaten.anlagen_im_bau_3', 'unternehmensdaten.anlagen_im_bau_2', 'unternehmensdaten.anlagen_im_bau_1' ],
      weiteresAvGroup: [ 'unternehmensdaten.weiteres_av_3', 'unternehmensdaten.weiteres_av_2', 'unternehmensdaten.weiteres_av_1' ],
      kassenbestandGroup: [ 'unternehmensdaten.kassenbestand_3', 'unternehmensdaten.kassenbestand_2', 'unternehmensdaten.kassenbestand_1' ],
      vorraeteGroup: [ 'unternehmensdaten.vorraete_3', 'unternehmensdaten.vorraete_2', 'unternehmensdaten.vorraete_1' ],
      forderungenSonstigeVgGroup: [ 'unternehmensdaten.forderungen_sonstige_vg_3', 'unternehmensdaten.forderungen_sonstige_vg_2', 'unternehmensdaten.forderungen_sonstige_vg_1' ],
      umlaufvermoegenGroup: [ 'unternehmensdaten.umlaufvermoegen_3', 'unternehmensdaten.umlaufvermoegen_2', 'unternehmensdaten.umlaufvermoegen_1' ],
      sonstigeAktivaGroup: [ 'unternehmensdaten.sonstige_aktiva_3', 'unternehmensdaten.sonstige_aktiva_2', 'unternehmensdaten.sonstige_aktiva_1' ],
      eigenkapitalGroup: [ 'unternehmensdaten.eigenkapital_3', 'unternehmensdaten.eigenkapital_2', 'unternehmensdaten.eigenkapital_1' ],
      rueckstellungenGroup: [ 'unternehmensdaten.rueckstellungen_3', 'unternehmensdaten.rueckstellungen_2', 'unternehmensdaten.rueckstellungen_1' ],
      verbindlichkeitenGroup: [ 'unternehmensdaten.verbindlichkeiten_3', 'unternehmensdaten.verbindlichkeiten_2', 'unternehmensdaten.verbindlichkeiten_1' ],
      fremdkapitalGroup: [ 'unternehmensdaten.fremdkapital_3', 'unternehmensdaten.fremdkapital_2', 'unternehmensdaten.fremdkapital_1' ],
      sonstigePassivaGroup: [ 'unternehmensdaten.sonstige_passiva_3', 'unternehmensdaten.sonstige_passiva_2', 'unternehmensdaten.sonstige_passiva_1' ],
    }
  },
  computed: {
    betrachtungsjahre() {
      const currentYear = new Date().getFullYear()
      const b = []

      b.istjahr_3 = currentYear - 1
      b.istjahr_2 = currentYear - 2
      b.istjahr_1 = currentYear - 3

      return b
    }
  },
  watch: {
    unternehmensdaten: {
      handler: function(u) {
        // summe aktiva
        u.summeAktiva_1 = u.grundstuecke_1 + u.maschinen_1 + u.geschaeftsausstattung_1 + u.anlagen_im_bau_1 + u.weiteres_av_1 + u.kassenbestand_1 + u.vorraete_1 + u.forderungen_sonstige_vg_1 + u.umlaufvermoegen_1 + u.sonstige_aktiva_1;
        u.summeAktiva_2 = u.grundstuecke_2 + u.maschinen_2 + u.geschaeftsausstattung_2 + u.anlagen_im_bau_2 + u.weiteres_av_2 + u.kassenbestand_2 + u.vorraete_2 + u.forderungen_sonstige_vg_2 + u.umlaufvermoegen_2 + u.sonstige_aktiva_2;
        u.summeAktiva_3 = u.grundstuecke_3 + u.maschinen_3 + u.geschaeftsausstattung_3 + u.anlagen_im_bau_3 + u.weiteres_av_3 + u.kassenbestand_3 + u.vorraete_3 + u.forderungen_sonstige_vg_3 + u.umlaufvermoegen_3 + u.sonstige_aktiva_3;

        // summe passiva
        u.summePassiva_1 = u.eigenkapital_1 + u.rueckstellungen_1 + u.verbindlichkeiten_1 + u.sonstige_passiva_1;
        u.summePassiva_2 = u.eigenkapital_2 + u.rueckstellungen_2 + u.verbindlichkeiten_2 + u.sonstige_passiva_2;
        u.summePassiva_3 = u.eigenkapital_3 + u.rueckstellungen_3 + u.verbindlichkeiten_3 + u.sonstige_passiva_3;
      },
      deep: true
    }
  },
  methods: {
    submitBack () {
      this.back(this.unternehmensdaten)
    },
    submitAnswer () {
      this.answer(this.unternehmensdaten)
    }
  }
}
</script>

<style lang="scss" scoped>

.kmu-financialdata--workflow-container {
  box-shadow: 0 4px 8px 0 rgba(17,22,26,.16),0 4px 8px 0 rgba(17,22,26,.08),0 8px 16px 0 rgba(17,22,26,.04);
  background-color: #e9e9e9;
  display: flex;
  padding: 1rem;
  font-style: italic;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.kmu-financialdata--error-container {
  margin-top: 3rem;
  margin-left: 3.5rem;
  margin-right: 3.25rem;
}

.validation-errors {
  color: red;
}
</style>
