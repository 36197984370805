import Question from './Question'

class QuestionManager {
  constructor (questions) {
    this.questions = []
    this.questionIds = []
    this.loadQuestions(questions)
  }

  loadQuestions (questions) {
    if (!questions || typeof questions !== 'object') {
      return
    }

    this.questions = []
    this.initQuestions(questions)
    this.questionIds = Object.getOwnPropertyNames(questions)
  }

  initQuestions (questions) {
    for (const id in questions) {
      this.questions.push(new Question(id, questions[id]))
    }
  }

  getQuestionIndex (questionId) {
    return this.questionIds.indexOf(questionId)
  }

  getQuestion (questionId) {
    const index = this.getQuestionIndex(questionId)

    if (index === -1) {
      return null
    }

    return this.questions[index]
  }

  getQuestions () {
    return this.questions
  }
}

export default QuestionManager
