<template>
  <div class="mb-4">
    <p class="h3 mb-3" v-if="title">
      {{ title }}
    </p>
    <p class="mb-4" v-if="description">
      <span v-html="description"/>
    </p>
    <p class="text-muted" v-if="help">
      <span v-html="help"/>
    </p>
  </div>
</template>

<script>
export default {
  name: 'question-header',
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    help: {
      type: String
    },
  }
}
</script>
