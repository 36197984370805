<template>
  <b-form-group
    :state="s && v.$invalid ? false : null"
    :invalid-feedback="invalidFeedback"
    >
    <div class="financial-data">{{ $n(value, 'money', 'de-DE') }}</div>
  </b-form-group>
</template>

<script>

export default {
  props: {
    id: {
      type: String
    },
    value: {
      required: true
    },
    s: {
      type: Boolean,
      default: true
    },
    v: {
      type: Object,
      required: true
    }
  },
  computed: {
    invalidFeedback() {
      if (this.v?.summeAktivaPassiva === false) {
        return "Summe Aktiva und Passiva müssen gleich sein!"
      }

      return null
    }
  }
}
</script>

<style>
.financial-data {
  text-align: right;
  font-weight: bold;
  padding-right: .5rem;
  padding-bottom: .5rem;
}
</style>
