import QuestionAction from './QuestionAction'

class Question {
  constructor (id, data) {
    this.id = id
    this.type = data.type || 'text'
    this.category = data.category || ''
    this.formatter = data.formatter || null
    this.validation = data.validation || null
    this.title = data.title
    this.description = data.description || null
    this.help = data.help || null
    this.invalid = data.invalid || null
    this.icon = data.icon || null
    this.prepend = data.prepend || false
    this.placeholder = data.placeholder || ''
    this.answers = data.answers
    this.action = new QuestionAction(data.action || {})
  }
}

export default Question
