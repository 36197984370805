<template>
  <b-container id="app">
    <b-row>
      <b-col class="mt-3">
        <b-img src="/kmuvalue-logo.svg" style="height:60px"/>
      </b-col>
      <b-col style="text-align: right;">
        <div id="nav">
          <router-link to="/">Start</router-link> |
          <a href="https://www.kmu-value.de" target="_blank">Homepage</a>
        </div>
      </b-col>
    </b-row>
    <router-view />
    <div v-if="debug">
      <debug />
    </div>
  </b-container>
</template>

<script>
import Debug from '@/views/Debug.vue'

export default {
  name: 'App',
  data () {
    return {
      debug: false
    }
  },
  metaInfo: {
    title: 'Unternehmensbewertung',
    titleTemplate: '%s | KMUValue'
  },
  components: {
    Debug
  }
}
</script>

<style lang="scss" scoped>
#app {
   font-family: Source Sans Pro, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: var(--kmu-color2);
    }
  }
}
</style>
