<template>
  <div class="text-right my-5 d-flex flex-row justify-content-between">
    <b-link class="btn btn-light"   @click.prevent="back" href="#">« Zurück</b-link>
    <div v-show="questionaireFinished">
      <b-link class="btn btn-primary" @click.prevent="summary" href="#" :disabled="preventNext">Direkt zur Zusammenfassung&nbsp;&nbsp;<b-icon icon="box-arrow-up-right"/></b-link>
    </div>
    <b-link class="btn btn-primary" @click.prevent="next" href="#" :disabled="preventNext">Weiter »</b-link>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'question-footer',

  emits: [
    'back',
    'next'
  ],
  props: {
    v: {
      type: Object
    }
  },
  methods: {
    ...mapMutations('questions', {
      setQuestionIndexByQuestionId: 'setQuestionIndexByQuestionId',
    }),
    back () {
      this.$emit('back')
    },
    summary () {
      this.$emit('next')
      this.setQuestionIndexByQuestionId('zusammenfassung')
    },
    next () {
      this.$emit('next')
    }
  },
  computed: {
    ...mapState('questions', {
      questionaireFinished: state => state.finished
    }),
    preventNext() {
      return this.v ? this.v.$invalid : false
    }
  }

}
</script>
