class QuestionAction {
  constructor ({ type, property }) {
    this.type = type || 'set'
    this.property = property
  }

  run (oldValue, newValue) {
    if (this.type === 'add' && oldValue) {
      return oldValue + newValue
    }

    return newValue
  }
}

export default QuestionAction
