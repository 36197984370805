import Vue from 'vue'
import VueRouter from 'vue-router'

import Intro from '@/views/Intro.vue'
import Questionaire from '@/views/Questionaire.vue'
import Result from '@/views/Result.vue'
import GdprConsent from '@/views/GdprConsent.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'intro', component: Intro },
  { path: '/questionaire/:index?', name: 'questionaire-index', component: Questionaire },
  { path: '/calculate', name: 'calculate', component: Result },
  { path: '/gdpr-consent', name: 'gdpr-consent', component: GdprConsent }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

export default router
