import Vue from 'vue'
import QuestionaireManager from '@/inc/QuestionaireManager'

const manager = new QuestionaireManager()

const getDefaultState = () => ({
  questions: manager.getQuestions(),
  currentQuestionIndex: 0,
  answers: {},
  finished: false
})

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setAnswerValue (state, { questionId, value }) {
      Vue.set(state.answers, questionId, value)
    },
    incrementQuestionIndex (state) {
      state.currentQuestionIndex++
    },
    decrementQuestionIndex (state) {
      state.currentQuestionIndex--
    },
    setQuestionIndex (state, questionIndex) {
      state.currentQuestionIndex = questionIndex
    },
    setQuestionIndexByQuestionId (state, questionId) {
      let questionIndex = manager.getQuestionIndex(questionId)
      if (questionIndex > 0) {
        state.currentQuestionIndex = questionIndex
      }
    },
    setAnswers (state, answers) {
      state.answers = answers
    },
    setQuestions (state, questions) {
      state.questions = questions
    },
    setFinished (state, finished) {
      state.finished = finished
    },
    resetModule (state) {
      // reset stores' state
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    startQuestionnaire ({ commit }) {
      commit('setQuestionIndex', 0)
    },
    showNextQuestion ({ commit, getters }) {
      if (!getters.hasNextQuestion) {
        return
      }
      commit('incrementQuestionIndex')
    },
    showPreviousQuestion ({ commit, getters }) {
      if (!getters.hasPreviousQuestion) {
        return
      }
      commit('decrementQuestionIndex')
    },
    showQuestionIndex ({ commit }, questionIndex) {
      commit('setQuestionIndex', questionIndex)
    },
    loadQuestions ({ commit }, questions) {
      manager.loadQuestions(questions)
      commit('setQuestions', manager.getQuestions())
    },
    setAnswer ({ commit, getters }, value) {
      const question = getters.currentQuestion
      const questionId = question.id
      commit('setAnswerValue', { questionId, value })
    }
  },
  getters: {
    getAnswers (state) {
      const answers = {}

      state.answers.forEach((answer) => {
        const questionId = answer.question
        const answerValue = answer.value

        const question = manager.getQuestion(questionId)
        const action = question.action
        const property = action.property
        const oldValue = answers[property]

        answers[property] = action.run(oldValue, answerValue)
      })

      return answers
    },
    currentQuestion (state) {
      return state.questions[state.currentQuestionIndex]
    },
    hasNextQuestion (state) {
      const nextIndex = state.currentQuestionIndex + 1
      return !!state.questions[nextIndex]
    },
    hasPreviousQuestion (state) {
      return state.currentQuestionIndex > 0
    },
    getProgress (state) {
      const questions = state.questions
      if (!questions) {
        return 0
      }
      return state.currentQuestionIndex / (questions.length - 1) * 100
    },
    questionCount (state) {
      return state.questions.length
    }
  }
}
