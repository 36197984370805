<template>
  <div class="home">
    <div>
      <h2 class="text-center mt-4 mb-5">{{ title }}</h2>
      <p class="question__description text-center" v-html="content"></p>
    </div>
  </div>
</template>

<script>
import { createDirectus, rest, readItem, triggerFlow } from '@directus/sdk';

const client = createDirectus('https://api.kmu-value.de').with(rest());

export default {
  name: 'GdprConsent',

  data() {
    return {
      title: '',
      content: '',
      user: null,
      case: null,
      report: null
    }
  },

  created() {
    this.fetchPage();
  },

  mounted() {
    this.user = this.$route.query.user
    this.case = this.$route.query.case
    this.report = this.$route.query.report

    this.commitConsent();
  },

  methods: {
    async fetchPage() {
      try {
        const slug = 'gdpr-consent';
        const result = await client.request(readItem('page', slug));

        this.title = result.title;
        this.content = result.content;

      } catch (error) {
        console.error('Error fetching page items:', error);
      }
    },
    async commitConsent() {
      try {
        if (this.user && this.case) {
          await client.request(triggerFlow('POST','987d5831-11ec-4f0f-90af-0c06f4298e19', {
            user: this.user,
            case: this.case
          }));
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    }
  }
}
</script>
