<template>
  <div>

    <question-header
      :title="question.title"
      :description="question.description"
      :help="question.help">
    </question-header>

    <b-card no-body>
      <b-tabs card>
        <b-tab>
          <template #title><b>Basisdaten &#x2192;</b></template>
          <b-card-body>
            <summary-details-table
              v-bind:question-ids="[
                'gruendung',
                'rechtsform',
                'abgrenzungTatigkeit1',
                'mitarbeiter',
                'standort',
                'branche'
              ]"
            />
          </b-card-body>
        </b-tab>
        <b-tab>
          <template #title><b>Abhängigkeiten &#x2192;</b></template>
          <b-card-body>
            <summary-details-table
              v-bind:question-ids="[
                'inhaberabhangigkeit',
                'inhaberpraegung',
                'personenabhangigkeit',
                'management',
                'kundenabhaengigkeit',
                'kundenbeziehung',
                'lieferantenabhaengigkeit',
                'produktabhaengigkeit',
                'finanzierungsabhaengigkeit',
                'verzahnung',
                'risiken',
                'standortabhaengigkeit'
              ]"
            />
          </b-card-body>
        </b-tab>
        <b-tab>
          <template #title><b>Unternehmensdaten &#x2192;</b></template>
          <b-card-body>
            <b-card  header-tag="nav">
              <template #header>
                <div class="d-flex flex-row justify-content-between">
                  <b>Gewinn- und Verlustrechnung</b>
                  <a class="summaryDetails-show-when-hovered" href="#"
                    @click="setQuestionIndexByQuestionId('unternehmensdaten_guv')">
                    <b-icon icon="pencil-fill"></b-icon>
                  </a>
                </div>
              </template>
              <b-table-simple hover small caption-top responsive>
                <b-thead>
                    <b-tr variant="light">
                      <b-th>Werte in €</b-th>
                      <b-th class="text-right">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                      <b-th class="text-right">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                      <b-th class="text-right">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Umsatzerlöse / Einnahmen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.umsatzerloese_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.umsatzerloese_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.umsatzerloese_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Bestandsveränderung</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.bestandsveraenderung_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.bestandsveraenderung_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.bestandsveraenderung_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Eigenleistung bzw. -verbrauch / Privatanteile</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.eigenleistung_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.eigenleistung_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.eigenleistung_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>Gesamtleistung</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_guv.gesamtleistung_1, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_guv.gesamtleistung_2, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_guv.gesamtleistung_3, 'money', 'de-DE') }}</b-th>
                  </b-tr>
                  <b-tr>
                    <b-td>Sonstige betriebliche Erträge</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.sonstige_ertraege_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.sonstige_ertraege_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.sonstige_ertraege_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Materialaufwand / bezogene Leistungen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.materialaufwand_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.materialaufwand_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.materialaufwand_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Personalaufwand</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.personalaufwand_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.personalaufwand_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.personalaufwand_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Abschreibungen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.abschreibungen_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.abschreibungen_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.abschreibungen_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Sonstige betriebliche Aufwendungen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.sonstige_aufwendungen_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.sonstige_aufwendungen_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.sonstige_aufwendungen_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr variant="light">
                    <b-th>EBIT</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_guv.ebit_1, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_guv.ebit_2, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_guv.ebit_3, 'money', 'de-DE') }}</b-th>
                  </b-tr>
                  <b-tr>
                    <b-td>Zinsertrag und ähnliche Erträge</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.zinsertrag_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.zinsertrag_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.zinsertrag_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Zinsaufwand und ähnliche Aufwendungen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.zinsaufwand_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.zinsaufwand_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.zinsaufwand_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Außerordentliche Erträge</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.ausserordentliche_ertraege_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.ausserordentliche_ertraege_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.ausserordentliche_ertraege_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Außerordentliche Aufwendungen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.ausserordentliche_aufwendungen_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.ausserordentliche_aufwendungen_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.ausserordentliche_aufwendungen_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Steuern vom Einkommen und Ertrag</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.steuern_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.steuern_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.steuern_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Sonstige Steuern</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.sonstige_steuern_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.sonstige_steuern_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_guv.sonstige_steuern_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr variant="light">
                    <b-th>Jahresüberschuss / Jahresfehlbetrag</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_guv.jahresueberschuss_1, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_guv.jahresueberschuss_2, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_guv.jahresueberschuss_3, 'money', 'de-DE') }}</b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>

            <p></p>
            <b-card  header-tag="nav">
              <template #header>
                <div class="d-flex flex-row justify-content-between">
                  <b>Korrekturen</b>
                  <a class="summaryDetails-show-when-hovered" href="#"
                    @click="setQuestionIndexByQuestionId('unternehmensdaten_korrekturen')">
                    <b-icon icon="pencil-fill"></b-icon>
                  </a>
                </div>
              </template>
              <b-table-simple hover small caption-top responsive>
                <b-thead>
                    <b-tr variant="light">
                      <b-th>Werte in €</b-th>
                      <b-th class="text-right">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                      <b-th class="text-right">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                      <b-th class="text-right">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Geschäftsführergehalt / Unternehmerlohn</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_korrekturen.personalaufwand_gf_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_korrekturen.personalaufwand_gf_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_korrekturen.personalaufwand_gf_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Marktübliches Geschäftsführergehalt</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_korrekturen.personalaufwand_gf_markt_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_korrekturen.personalaufwand_gf_markt_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_korrekturen.personalaufwand_gf_markt_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Zusätzliche ergebnisbeeinflussende Effekte</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_korrekturen.sonstige_effekte_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_korrekturen.sonstige_effekte_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_korrekturen.sonstige_effekte_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>

            <p></p>
            <b-card  header-tag="nav">
              <template #header>
                <div class="d-flex flex-row justify-content-between">
                  <b>Bilanz (optional)</b>
                  <a class="summaryDetails-show-when-hovered" href="#"
                    @click="setQuestionIndexByQuestionId('unternehmensdaten_bilanz')">
                    <b-icon icon="pencil-fill"></b-icon>
                  </a>
                </div>
              </template>
              <b-table-simple hover small caption-top responsive>
                <b-thead>
                    <b-tr variant="light">
                      <b-th>Aktiva in €</b-th>
                      <b-th class="text-right">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                      <b-th class="text-right">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                      <b-th class="text-right">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Grundstücke und Gebäude</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.grundstuecke_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.grundstuecke_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.grundstuecke_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Technische Anlagen und Maschinen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.maschinen_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.maschinen_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.maschinen_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Betriebs- und Geschäftsausstattung</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.geschaeftsausstattung_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.geschaeftsausstattung_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.geschaeftsausstattung_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Geleistete Anzahlungen und Anlagen im Bau</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.anlagen_im_bau_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.anlagen_im_bau_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.anlagen_im_bau_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Weiteres Anlagevermögen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.weiteres_av_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.weiteres_av_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.weiteres_av_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Kassenbestand</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.kassenbestand_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.kassenbestand_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.kassenbestand_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Vorräte</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.vorraete_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.vorraete_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.vorraete_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Forderungen und sonstige Vermögensgegenstände</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.forderungen_sonstige_vg_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.forderungen_sonstige_vg_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.forderungen_sonstige_vg_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Weiteres Umlaufvermögen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.umlaufvermoegen_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.umlaufvermoegen_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.umlaufvermoegen_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Sonstige Aktiva / Rechnungsabgrenzungsposten</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.sonstige_aktiva_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.sonstige_aktiva_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.sonstige_aktiva_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr variant="light">
                    <b-th>Summe Aktiva</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_bilanz.summeAktiva_1, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_bilanz.summeAktiva_2, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_bilanz.summeAktiva_3, 'money', 'de-DE') }}</b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-table-simple hover small caption-top responsive>
                <b-thead>
                  <b-tr variant="light">
                    <b-th>Passiva in €</b-th>
                    <b-th class="text-right">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                    <b-th class="text-right">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                    <b-th class="text-right">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Eigenkapital</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.eigenkapital_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.eigenkapital_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.eigenkapital_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Rückstellungen</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.rueckstellungen_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.rueckstellungen_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.rueckstellungen_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Verbindlichkeiten</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.verbindlichkeiten_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.verbindlichkeiten_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.verbindlichkeiten_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><em>davon verzinsliches Fremdkapital</em></b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.fremdkapital_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.fremdkapital_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.fremdkapital_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Sonstige Passiva / Rechnungsabgrenzungsposten</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.sonstige_passiva_1, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.sonstige_passiva_2, 'money', 'de-DE') }}</b-td>
                    <b-td class="text-right" style="width: 10em">{{ $n(answers.unternehmensdaten_bilanz.sonstige_passiva_3, 'money', 'de-DE') }}</b-td>
                  </b-tr>
                  <b-tr variant="light">
                    <b-th>Summe Passiva</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_bilanz.summePassiva_1, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_bilanz.summePassiva_2, 'money', 'de-DE') }}</b-th>
                    <b-th class="text-right">{{ $n(answers.unternehmensdaten_bilanz.summePassiva_3, 'money', 'de-DE') }}</b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>

            <p></p>
            <b-card  header-tag="nav">
              <template #header><b>Sonstige Angaben</b></template>
              <summary-details-table
                v-bind:question-ids="[
                  'alter-av',
                  'nbv',
                  'marktwert-grundstuecke'
                ]"
              />
            </b-card>
          </b-card-body>
        </b-tab>
        <b-tab>
          <template #title><b>Planung</b></template>
          <b-card-body>
            <summary-details-table
              v-bind:question-ids="[
                'turnover_planning_attributes',
                'investitionsvolumen'
              ]"
            />
          </b-card-body>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-form action="" @submit.prevent="finish">

      <div class="kmu-financialdata--workflow-container">
        <b-form-checkbox v-model="workflow.stepCompleted" :v="$v.workflow.stepCompleted">
          Meine Angaben zur Unternehmensbewertung sind <strong>vollständig</strong>.
        </b-form-checkbox>
      </div>

      <div class="text-right my-5 d-flex flex-row justify-content-between">
        <a class="btn btn-light" @click.prevent="back" href="#">« Zurück</a>
        <b-button :disabled="$v.$invalid" type="submit" variant="primary">Bewertung starten »</b-button>
      </div>

    </b-form>

  </div>
</template>

<script>
import QuestionType from '@/inc/QuestionType'
import QuestionHeader from '@/components/QuestionHeader.vue'
import SummaryDetailsTable from '@/components/QuestionTypes/SummaryDetailsTable.vue'

import { mapState, mapMutations } from 'vuex'

export default {
  extends: QuestionType,
  components: {
    QuestionHeader,
    SummaryDetailsTable
  },
  data() {
    return {
      workflow: {
        stepCompleted: false
      }
    }
  },
  mounted() {
    this.setQuestionaireFinished(true)
  },
  validations() {
    return {
      workflow: {
        stepCompleted: { checked: value => value === true }
      },
    }
  },
  methods: {
    ...mapMutations({
      resetValuation: "resetValuation"
    }),
    ...mapMutations('questions', {
      setQuestionIndexByQuestionId: 'setQuestionIndexByQuestionId',
      setQuestionaireFinished: 'setFinished',
    }),
    finish() {
      this.resetValuation()
      this.$router.push({ name: 'calculate' })
    }
  },
  computed: {
    betrachtungsjahre() {
      const currentYear = new Date().getFullYear()
      const b = []

      b.istjahr_3 = currentYear - 1
      b.istjahr_2 = currentYear - 2
      b.istjahr_1 = currentYear - 3

      return b
    },
    ...mapState('questions', {
      answers: state => state.answers
    }),
  }
}
</script>

<style lang="scss">

.kmu-financialdata--workflow-container {
  box-shadow: 0 4px 8px 0 rgba(17,22,26,.16),0 4px 8px 0 rgba(17,22,26,.08),0 8px 16px 0 rgba(17,22,26,.04);
  background-color: #e9e9e9;
  display: flex;
  padding: 1rem;
  font-style: italic;
  margin-top: 2rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

@media (pointer:fine) {
    .b-table > tbody > tr:not(:hover) .show-when-hovered {
      display: none;
    }
}

</style>
