<template>
  <b-progress :value="progress" :max="100" height="5px"></b-progress>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('questions', {
      progress: 'getProgress'
    })
  }
}
</script>

<style lang="scss" scoped>
.progress {
  background-color: #e9e9e9;
}
</style>
