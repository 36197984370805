import QuestionSelect from './Select.vue'
import QuestionMultiNumber from './MultiNumber'
import QuestionText from './Text.vue'
import QuestionEmail from './Email.vue'
import QuestionFinancialGuv from './FinancialDataGuv.vue'
import QuestionFinancialKorrekturen from './FinancialDataKorrekturen.vue'
import QuestionFinancialBilanz from './FinancialDataBilanz.vue'
import QuestionSummary from './Summary.vue'

const questionComponents = {
  'question-select': QuestionSelect,
  'question-multinumber': QuestionMultiNumber,
  'question-text': QuestionText,
  'question-email': QuestionEmail,
  'question-financial-guv': QuestionFinancialGuv,
  'question-financial-korrekturen': QuestionFinancialKorrekturen,
  'question-financial-bilanz': QuestionFinancialBilanz,
  'question-summary': QuestionSummary
}

export default questionComponents
