<template>

  <div>
    <question-header
      :title="question.title"
      :description="question.description"
      :help="question.help">
    </question-header>

    <b-form action="" v-on:submit.prevent="submitAnswer">

      <div>
        <b-card no-body>
          <b-tabs card>
            <b-tab>
              <template #title>
                <h6>1. Gewinn- und Verlustrechnung &#x2192;</h6>
              </template>
              <b-card-body>
                <b-card-text class="mb-4">
                  Es folgt die Datenerhebung der betriebswirtschaftlichen Kennzahlen Ihres Unternehmens in drei Schritten. Bitte halten Sie hierfür möglichst Ihre letzten drei Jahresabschlüsse (Gewinn- und Verlustrechnung, Bilanz), betriebswirtschaftliche Auswertungen ("BWA") sowie ggf. Summen- und Saldenlisten („SuSa“) bereit.
                </b-card-text>
                <b-table-simple hover responsive>
                  <b-thead>
                      <b-tr variant="light">
                        <b-th>Werte in €</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_1 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_2 }}</b-th>
                        <b-th class="text-right" style="padding-right: 1.5rem">{{ betrachtungsjahre.istjahr_3 }}</b-th>
                      </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>Umsatzerlöse / Einnahmen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.umsatzerloese_1" :v="$v.unternehmensdaten.umsatzerloese_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.umsatzerloese_2" :v="$v.unternehmensdaten.umsatzerloese_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.umsatzerloese_3" :v="$v.unternehmensdaten.umsatzerloese_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Bestandsveränderung
                        <b-icon id="label_bestandsveraenderung" icon="info-circle-fill" style="color: #9DBC2D;"></b-icon>
                        <b-tooltip target="label_bestandsveraenderung" triggers="hover">
                          Die Bestandsveränderung bezieht sich ausschließlich auf unfertige Erzeugnisse (bzw. unfertige Leistungen) und fertige Erzeugnisse und deren Veränderung im Vergleich zum Vorjahr. Bei Architekturbüros können hier auch die in Auftrag befindlichen Projekte gebucht sein.
                        </b-tooltip>
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.bestandsveraenderung_1" :v="$v.unternehmensdaten.bestandsveraenderung_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.bestandsveraenderung_2" :v="$v.unternehmensdaten.bestandsveraenderung_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.bestandsveraenderung_3" :v="$v.unternehmensdaten.bestandsveraenderung_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Eigenleistung bzw. -verbrauch / Privatanteile</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.eigenleistung_1" :v="$v.unternehmensdaten.eigenleistung_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.eigenleistung_2" :v="$v.unternehmensdaten.eigenleistung_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.eigenleistung_3" :v="$v.unternehmensdaten.eigenleistung_3" />
                      </b-td>
                    </b-tr>
                    <b-tr variant="light">
                      <b-th>Gesamtleistung</b-th>
                      <b-th class="text-right" style="padding-bottom: 2rem; padding-right: 1.5rem">{{ $n(unternehmensdaten.gesamtleistung_1, 'money', 'de-DE') }}</b-th>
                      <b-th class="text-right" style="padding-bottom: 2rem; padding-right: 1.5rem">{{ $n(unternehmensdaten.gesamtleistung_2, 'money', 'de-DE') }}</b-th>
                      <b-th class="text-right" style="padding-bottom: 2rem; padding-right: 1.5rem">{{ $n(unternehmensdaten.gesamtleistung_3, 'money', 'de-DE') }}</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td>Sonstige betriebliche Erträge</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_ertraege_1" :v="$v.unternehmensdaten.sonstige_ertraege_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_ertraege_2" :v="$v.unternehmensdaten.sonstige_ertraege_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_ertraege_3" :v="$v.unternehmensdaten.sonstige_ertraege_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Materialaufwand / bezogene Leistungen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.materialaufwand_1" :v="$v.unternehmensdaten.materialaufwand_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.materialaufwand_2" :v="$v.unternehmensdaten.materialaufwand_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.materialaufwand_3" :v="$v.unternehmensdaten.materialaufwand_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        Personalaufwand
                        <span v-if="false"><b-link href="#"><b-icon icon="exclamation-triangle-fill" font-scale="1" variant="warning" v-b-popover.hover="'Bei Personengesellschaften (z.B. Einzelunternehmen, KG, PartmbB, u.a.) ist das Geschäftsführergehalt meist nicht in den Personalkosten enthalten. Bitte Gehälter unter Korrekturen anpassen.'" title="Korrektur nötig!"></b-icon></b-link></span>
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.personalaufwand_1" :s="false" :v="$v.unternehmensdaten.personalaufwand_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.personalaufwand_2" :s="false" :v="$v.unternehmensdaten.personalaufwand_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.personalaufwand_3" :s="false" :v="$v.unternehmensdaten.personalaufwand_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Abschreibungen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.abschreibungen_1" :v="$v.unternehmensdaten.abschreibungen_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.abschreibungen_2" :v="$v.unternehmensdaten.abschreibungen_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.abschreibungen_3" :v="$v.unternehmensdaten.abschreibungen_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Sonstige betriebliche Aufwendungen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_aufwendungen_1" :v="$v.unternehmensdaten.sonstige_aufwendungen_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_aufwendungen_2" :v="$v.unternehmensdaten.sonstige_aufwendungen_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_aufwendungen_3" :v="$v.unternehmensdaten.sonstige_aufwendungen_3" />
                      </b-td>
                    </b-tr>
                    <b-tr variant="light">
                      <b-th>EBIT</b-th>
                      <b-th class="text-right" style="padding-bottom: 2rem; padding-right: 1.5rem">{{ $n(unternehmensdaten.ebit_1, 'money', 'de-DE') }}</b-th>
                      <b-th class="text-right" style="padding-bottom: 2rem; padding-right: 1.5rem">{{ $n(unternehmensdaten.ebit_2, 'money', 'de-DE') }}</b-th>
                      <b-th class="text-right" style="padding-bottom: 2rem; padding-right: 1.5rem">{{ $n(unternehmensdaten.ebit_3, 'money', 'de-DE') }}</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td>Zinsertrag und ähnliche Erträge</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.zinsertrag_1" :v="$v.unternehmensdaten.zinsertrag_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.zinsertrag_2" :v="$v.unternehmensdaten.zinsertrag_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.zinsertrag_3" :v="$v.unternehmensdaten.zinsertrag_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Zinsaufwand und ähnliche Aufwendungen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.zinsaufwand_1" :v="$v.unternehmensdaten.zinsaufwand_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.zinsaufwand_2" :v="$v.unternehmensdaten.zinsaufwand_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.zinsaufwand_3" :v="$v.unternehmensdaten.zinsaufwand_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Außerordentliche Erträge</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.ausserordentliche_ertraege_1" :v="$v.unternehmensdaten.ausserordentliche_ertraege_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.ausserordentliche_ertraege_2" :v="$v.unternehmensdaten.ausserordentliche_ertraege_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.ausserordentliche_ertraege_3" :v="$v.unternehmensdaten.ausserordentliche_ertraege_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Außerordentliche Aufwendungen</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.ausserordentliche_aufwendungen_1" :v="$v.unternehmensdaten.ausserordentliche_aufwendungen_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.ausserordentliche_aufwendungen_2" :v="$v.unternehmensdaten.ausserordentliche_aufwendungen_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.ausserordentliche_aufwendungen_3" :v="$v.unternehmensdaten.ausserordentliche_aufwendungen_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Steuern vom Einkommen und Ertrag</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.steuern_1" :v="$v.unternehmensdaten.steuern_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.steuern_2" :v="$v.unternehmensdaten.steuern_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.steuern_3" :v="$v.unternehmensdaten.steuern_3" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Sonstige Steuern</b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_steuern_1" :v="$v.unternehmensdaten.sonstige_steuern_1" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_steuern_2" :v="$v.unternehmensdaten.sonstige_steuern_2" />
                      </b-td>
                      <b-td style="width: 10em">
                        <financial-data-input v-model="unternehmensdaten.sonstige_steuern_3" :v="$v.unternehmensdaten.sonstige_steuern_3" />
                      </b-td>
                    </b-tr>
                    <b-tr variant="light">
                      <b-th>Jahresüberschuss / Jahresfehlbetrag</b-th>
                      <b-th class="text-right" style="padding-bottom: 2rem; padding-right: 1.5rem">{{ $n(unternehmensdaten.jahresueberschuss_1, 'money', 'de-DE') }}</b-th>
                      <b-th class="text-right" style="padding-bottom: 2rem; padding-right: 1.5rem">{{ $n(unternehmensdaten.jahresueberschuss_2, 'money', 'de-DE') }}</b-th>
                      <b-th class="text-right" style="padding-bottom: 2rem; padding-right: 1.5rem">{{ $n(unternehmensdaten.jahresueberschuss_3, 'money', 'de-DE') }}</b-th>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-card-text style="padding: 0.9rem;">
                  <em>Hinweis: Bitte plausibilisieren Sie den durch KMUVALUE errechneten Jahresüberschuss für jedes Ihrer erfassten Geschäftsjahre mit den in Ihrem Jahresabschluss ausgewiesenen Werten. Sollten hier im Rahmen der Erfassung von Erträgen und Aufwendungen Abweichungen bestehen, möchten wir Sie bitten, Werte aus Ihrem Abschluss, die sich nicht positionsgetreu zuordnen lassen, in den Positionen „Sonstige betriebliche Erträge“ bzw. „Sonstige betriebliche Aufwendungen“ zu berücksichtigen.</em>
                </b-card-text>
                <div class="kmu-financialdata--workflow-container">
                  <b-form-checkbox v-model="workflow.stepCompleted" :v="$v.workflow.stepCompleted">
                    Meine Eingaben für den Bereich <strong>Gewinn- und Verlustrechnung</strong> sind vollständig.
                  </b-form-checkbox>
                </div>
              </b-card-body>
            </b-tab>
            <b-tab disabled>
              <template #title>
                <h6>2. Korrekturen &#x2192;</h6>
              </template>
            </b-tab>
            <b-tab disabled>
              <template #title>
                <h6>3. Bilanz (ergänzend)</h6>
              </template>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>

      <div v-if="false" class="kmu-financialdata--error-container validation-errors">
        <label><strong>Bei der Validierung Ihrer Eingaben sind uns Unstimmigkeiten aufgefallen!</strong></label>
        <ul>
          <li>Sie haben Personalaufwände erfaßt, aber weder das Geschäftsführergehalt spezifiziert noch ein marktübliches Geschäftsführergehalt angegeben. Wechseln Sie in den Bereich Korrekturen um diese Daten zu erfassen.</li>
        </ul>
      </div>

      <question-footer
        @back="submitBack"
        @next="submitAnswer"
        v-bind:v="$v"
      />

    </b-form>

  </div>
</template>

<script>
import QuestionType from '@/inc/QuestionType'
import QuestionHeader from '@/components/QuestionHeader.vue'
import QuestionFooter from '@/components/QuestionFooter.vue'
import FinancialDataInput from '@/components/FinancialData/FinancialDataInput.vue'

import { helpers, required, integer } from 'vuelidate/lib/validators'

const almostEqual = (compareToField1, compareToField2) =>
  helpers.withParams(
    { type: 'almostEqual', compareToField1, compareToField2 },
    function(value, parentVm) {

      let num1 = Math.abs(parseFloat(value))
      let num2 = Math.abs(parseFloat(helpers.ref(compareToField1, this, parentVm)))
      let num3 = Math.abs(parseFloat(helpers.ref(compareToField2, this, parentVm)))

      let values = [num1, num2, num3].sort( function(a,b) { return a - b; } );

      let median = 0
      let biggest = values[values.length-1]

      // calculate median value to identify possible outbreaks ...
      let half = Math.floor(values.length/2);
      if (values.length % 2)
          median = values[half];
      else
          median = (values[half-1] + values[half]) / 2.0;

      // epsilon is assumend to be 10-times bigger than the
      // median - which is definitely subject to adjust
      let epsilon = median * 10

      // FIXME: essentially switch off validato
      epsilon = 0

      // if epsilon is equal to 0 we do assume columns are not (yet) filled
      if ( epsilon == 0 ) {
        return true
      } else {
        return biggest <= epsilon
      }
    }
  )

export default {
  extends: QuestionType,

  components: {
    QuestionHeader,
    FinancialDataInput,
    QuestionFooter
  },

  data () {
    return {
      workflow: {
        stepCompleted: false
      },
      unternehmensdaten: {
        umsatzerloese_1: 0,
        umsatzerloese_2: 0,
        umsatzerloese_3: 0,
        bestandsveraenderung_1: 0,
        bestandsveraenderung_2: 0,
        bestandsveraenderung_3: 0,
        eigenleistung_1: 0,
        eigenleistung_2: 0,
        eigenleistung_3: 0,
        sonstige_ertraege_1: 0,
        sonstige_ertraege_2: 0,
        sonstige_ertraege_3: 0,
        materialaufwand_1: 0,
        materialaufwand_2: 0,
        materialaufwand_3: 0,
        personalaufwand_1: 0,
        personalaufwand_2: 0,
        personalaufwand_3: 0,
        abschreibungen_1: 0,
        abschreibungen_2: 0,
        abschreibungen_3: 0,
        sonstige_aufwendungen_1: 0,
        sonstige_aufwendungen_2: 0,
        sonstige_aufwendungen_3: 0,
        zinsertrag_1: 0,
        zinsertrag_2: 0,
        zinsertrag_3: 0,
        zinsaufwand_1: 0,
        zinsaufwand_2: 0,
        zinsaufwand_3: 0,
        ausserordentliche_ertraege_1: 0,
        ausserordentliche_ertraege_2: 0,
        ausserordentliche_ertraege_3: 0,
        ausserordentliche_aufwendungen_1: 0,
        ausserordentliche_aufwendungen_2: 0,
        ausserordentliche_aufwendungen_3: 0,
        steuern_1: 0,
        steuern_2: 0,
        steuern_3: 0,
        sonstige_steuern_1: 0,
        sonstige_steuern_2: 0,
        sonstige_steuern_3: 0,
        gesamtleistung_1: 0,
        gesamtleistung_2: 0,
        gesamtleistung_3: 0,
        ebit_1: 0,
        ebit_2: 0,
        ebit_3: 0,
        jahresueberschuss_1: 0,
        jahresueberschuss_2: 0,
        jahresueberschuss_3: 0
      },
    }
  },
  mounted() {
    if (this.currentAnswer) {
      // fill local data with current answer and vuex state
      this.unternehmensdaten = Object.assign({}, this.unternehmensdaten, this.currentAnswer)
    }
  },
  validations() {
    return {
      workflow: {
        stepCompleted: { checked: value => value === true }
      },
      unternehmensdaten: {
        umsatzerloese_1: { required, integer, almostEqual: almostEqual('umsatzerloese_2', 'umsatzerloese_3') },
        umsatzerloese_2: { required, integer, almostEqual: almostEqual('umsatzerloese_1', 'umsatzerloese_3') },
        umsatzerloese_3: { required, integer, almostEqual: almostEqual('umsatzerloese_2', 'umsatzerloese_1') },
        bestandsveraenderung_1: { required, integer, almostEqual: almostEqual('bestandsveraenderung_2', 'bestandsveraenderung_3') },
        bestandsveraenderung_2: { required, integer, almostEqual: almostEqual('bestandsveraenderung_1', 'bestandsveraenderung_3') },
        bestandsveraenderung_3: { required, integer, almostEqual: almostEqual('bestandsveraenderung_1', 'bestandsveraenderung_2') },
        eigenleistung_1: { required, integer, almostEqual: almostEqual('eigenleistung_2', 'eigenleistung_3') },
        eigenleistung_2: { required, integer, almostEqual: almostEqual('eigenleistung_1', 'eigenleistung_3') },
        eigenleistung_3: { required, integer, almostEqual: almostEqual('eigenleistung_1', 'eigenleistung_2') },
        sonstige_ertraege_1: { required, integer, almostEqual: almostEqual('sonstige_ertraege_2', 'sonstige_ertraege_3') },
        sonstige_ertraege_2: { required, integer, almostEqual: almostEqual('sonstige_ertraege_1', 'sonstige_ertraege_3') },
        sonstige_ertraege_3: { required, integer, almostEqual: almostEqual('sonstige_ertraege_1', 'sonstige_ertraege_2') },
        materialaufwand_1: { required, integer, almostEqual: almostEqual('materialaufwand_2', 'materialaufwand_3') },
        materialaufwand_2: { required, integer, almostEqual: almostEqual('materialaufwand_1', 'materialaufwand_3') },
        materialaufwand_3: { required, integer, almostEqual: almostEqual('materialaufwand_1', 'materialaufwand_2') },
        personalaufwand_1: {
          required,
          integer,
          almostEqual: almostEqual('personalaufwand_2', 'personalaufwand_3')
        },
        personalaufwand_2: {
          required,
          integer,
          almostEqual: almostEqual('personalaufwand_1', 'personalaufwand_3')
        },
        personalaufwand_3: {
          required,
          integer,
          almostEqual: almostEqual('personalaufwand_1', 'personalaufwand_2')
        },
        abschreibungen_1: { required, integer, almostEqual: almostEqual('abschreibungen_2', 'abschreibungen_3') },
        abschreibungen_2: { required, integer, almostEqual: almostEqual('abschreibungen_1', 'abschreibungen_3') },
        abschreibungen_3: { required, integer, almostEqual: almostEqual('abschreibungen_1', 'abschreibungen_2') },
        sonstige_aufwendungen_1: { required, integer, almostEqual: almostEqual('sonstige_aufwendungen_2', 'sonstige_aufwendungen_3') },
        sonstige_aufwendungen_2: { required, integer, almostEqual: almostEqual('sonstige_aufwendungen_1', 'sonstige_aufwendungen_3') },
        sonstige_aufwendungen_3: { required, integer, almostEqual: almostEqual('sonstige_aufwendungen_1', 'sonstige_aufwendungen_2') },
        zinsertrag_1: { required, integer, almostEqual: almostEqual('zinsertrag_2', 'zinsertrag_3') },
        zinsertrag_2: { required, integer, almostEqual: almostEqual('zinsertrag_1', 'zinsertrag_3') },
        zinsertrag_3: { required, integer, almostEqual: almostEqual('zinsertrag_1', 'zinsertrag_2') },
        zinsaufwand_1: { required, integer, almostEqual: almostEqual('zinsaufwand_2', 'zinsaufwand_3') },
        zinsaufwand_2: { required, integer, almostEqual: almostEqual('zinsaufwand_1', 'zinsaufwand_3') },
        zinsaufwand_3: { required, integer, almostEqual: almostEqual('zinsaufwand_1', 'zinsaufwand_2') },
        ausserordentliche_ertraege_1: { required, integer, almostEqual: almostEqual('ausserordentliche_ertraege_2', 'ausserordentliche_ertraege_3') },
        ausserordentliche_ertraege_2: { required, integer, almostEqual: almostEqual('ausserordentliche_ertraege_1', 'ausserordentliche_ertraege_3') },
        ausserordentliche_ertraege_3: { required, integer, almostEqual: almostEqual('ausserordentliche_ertraege_1', 'ausserordentliche_ertraege_2') },
        ausserordentliche_aufwendungen_1: { required, integer, almostEqual: almostEqual('ausserordentliche_aufwendungen_2', 'ausserordentliche_aufwendungen_3') },
        ausserordentliche_aufwendungen_2: { required, integer, almostEqual: almostEqual('ausserordentliche_aufwendungen_1', 'ausserordentliche_aufwendungen_3') },
        ausserordentliche_aufwendungen_3: { required, integer, almostEqual: almostEqual('ausserordentliche_aufwendungen_1', 'ausserordentliche_aufwendungen_2') },
        steuern_1: { required, integer, almostEqual: almostEqual('steuern_2', 'steuern_3') },
        steuern_2: { required, integer, almostEqual: almostEqual('steuern_1', 'steuern_3') },
        steuern_3: { required, integer, almostEqual: almostEqual('steuern_1', 'steuern_2') },
        sonstige_steuern_1: { required, integer, almostEqual: almostEqual('sonstige_steuern_2', 'sonstige_steuern_3') },
        sonstige_steuern_2: { required, integer, almostEqual: almostEqual('sonstige_steuern_1', 'sonstige_steuern_3') },
        sonstige_steuern_3: { required, integer, almostEqual: almostEqual('sonstige_steuern_1', 'sonstige_steuern_2') },
      },
      umsatzerloeseGroup: [ 'unternehmensdaten.umsatzerloese_3', 'unternehmensdaten.umsatzerloese_2', 'unternehmensdaten.umsatzerloese_1' ],
      bestandsveraenderungGroup: [ 'unternehmensdaten.bestandsveraenderung_3', 'unternehmensdaten.bestandsveraenderung_2', 'unternehmensdaten.bestandsveraenderung_1' ],
      eigenleistungGroup: [ 'unternehmensdaten.eigenleistung_3', 'unternehmensdaten.eigenleistung_2', 'unternehmensdaten.eigenleistung_1' ],
      sonstigeErtraegeGroup: [ 'unternehmensdaten.sonstige_ertraege_3', 'unternehmensdaten.sonstige_ertraege_2', 'unternehmensdaten.sonstige_ertraege_1' ],
      materialaufwandGroup: [ 'unternehmensdaten.materialaufwand_3', 'unternehmensdaten.materialaufwand_2', 'unternehmensdaten.materialaufwand_1' ],
      personalaufwandGroup: [ 'unternehmensdaten.personalaufwand_3', 'unternehmensdaten.personalaufwand_2', 'unternehmensdaten.personalaufwand_1' ],
      abschreibungenGroup: [ 'unternehmensdaten.abschreibungen_3', 'unternehmensdaten.abschreibungen_2', 'unternehmensdaten.abschreibungen_1' ],
      sonstigeAufwendungenGroup: [ 'unternehmensdaten.sonstige_aufwendungen_3', 'unternehmensdaten.sonstige_aufwendungen_2', 'unternehmensdaten.sonstige_aufwendungen_1' ],
      zinsertragGroup: [ 'unternehmensdaten.zinsertrag_3', 'unternehmensdaten.zinsertrag_2', 'unternehmensdaten.zinsertrag_1' ],
      zinsaufwandGroup: [ 'unternehmensdaten.zinsaufwand_3', 'unternehmensdaten.zinsaufwand_2', 'unternehmensdaten.zinsaufwand_1' ],
      ausserordentlicheErtraegeGroup: [ 'unternehmensdaten.ausserordentliche_ertraege_3', 'unternehmensdaten.ausserordentliche_ertraege_2', 'unternehmensdaten.ausserordentliche_ertraege_1' ],
      ausserordentlicheAufwendungenGroup: [ 'unternehmensdaten.ausserordentliche_aufwendungen_3', 'unternehmensdaten.ausserordentliche_aufwendungen_2', 'unternehmensdaten.ausserordentliche_aufwendungen_1' ],
      steuernGroup: [ 'unternehmensdaten.steuern_3', 'unternehmensdaten.steuern_2', 'unternehmensdaten.steuern_1' ],
      sonstigeSteuernGroup: [ 'unternehmensdaten.sonstige_steuern_3', 'unternehmensdaten.sonstige_steuern_2', 'unternehmensdaten.sonstige_steuern_1' ]
    }
  },
  computed: {
    betrachtungsjahre() {
      const currentYear = new Date().getFullYear()
      const b = []

      b.istjahr_3 = '' + currentYear - 1
      b.istjahr_2 = '' + currentYear - 2
      b.istjahr_1 = '' + currentYear - 3

      return b
    },
    isKorrrekturPlausible() {
      const u = this.unternehmensdaten
      return {
        korrektur_3: !u.jahresueberschuss_3 == 0,
        korrektur_2: !u.jahresueberschuss_2 == 0,
        korrektur_1: !u.jahresueberschuss_1 == 0
      }
    },
  },
  watch: {
    unternehmensdaten: {
      handler: function(u) {
        // gesamtleistung
        u.gesamtleistung_3 = Number(u.umsatzerloese_3) + Number(u.bestandsveraenderung_3) + Number(u.eigenleistung_3)
        u.gesamtleistung_2 = Number(u.umsatzerloese_2) + Number(u.bestandsveraenderung_2) + Number(u.eigenleistung_2)
        u.gesamtleistung_1 = Number(u.umsatzerloese_1) + Number(u.bestandsveraenderung_1) + Number(u.eigenleistung_1)

        // ebit
        u.ebit_3 = u.gesamtleistung_3 + Number(u.sonstige_ertraege_3) - Number(u.materialaufwand_3) - Number(u.personalaufwand_3) - Number(u.abschreibungen_3) - Number(u.sonstige_aufwendungen_3)
        u.ebit_2 = u.gesamtleistung_2 + Number(u.sonstige_ertraege_2) - Number(u.materialaufwand_2) - Number(u.personalaufwand_2) - Number(u.abschreibungen_2) - Number(u.sonstige_aufwendungen_2)
        u.ebit_1 = u.gesamtleistung_1 + Number(u.sonstige_ertraege_1) - Number(u.materialaufwand_1) - Number(u.personalaufwand_1) - Number(u.abschreibungen_1) - Number(u.sonstige_aufwendungen_1)

        // jahresueberschuss
        u.jahresueberschuss_3 = u.ebit_3 + Number(u.zinsertrag_3) - Number(u.zinsaufwand_3) + Number(u.ausserordentliche_ertraege_3) - Number(u.ausserordentliche_aufwendungen_3) - Number(u.steuern_3) - Number(u.sonstige_steuern_3)
        u.jahresueberschuss_2 = u.ebit_2 + Number(u.zinsertrag_2) - Number(u.zinsaufwand_2) + Number(u.ausserordentliche_ertraege_2) - Number(u.ausserordentliche_aufwendungen_2) - Number(u.steuern_2) - Number(u.sonstige_steuern_2)
        u.jahresueberschuss_1 = u.ebit_1 + Number(u.zinsertrag_1) - Number(u.zinsaufwand_1) + Number(u.ausserordentliche_ertraege_1) - Number(u.ausserordentliche_aufwendungen_1) - Number(u.steuern_1) - Number(u.sonstige_steuern_1)
      },
      deep: true
    }
  },
  methods: {
    submitBack () {
      this.back(this.unternehmensdaten)
    },
    submitAnswer () {
      this.answer(this.unternehmensdaten)
    }
  }
}
</script>

<style lang="scss" scoped>

.kmu-financialdata--workflow-container {
  box-shadow: 0 4px 8px 0 rgba(17,22,26,.16),0 4px 8px 0 rgba(17,22,26,.08),0 8px 16px 0 rgba(17,22,26,.04);
  background-color: #e9e9e9;
  display: flex;
  padding: 1rem;
  font-style: italic;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.kmu-financialdata--error-container {
  margin-top: 3rem;
  margin-left: 3.5rem;
  margin-right: 3.25rem;
}

.validation-errors {
  color: red;
}

</style>
