var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('question-header',{attrs:{"title":_vm.question.title,"description":_vm.question.description,"help":_vm.question.help}}),_c('div',{staticClass:"list-group mb-4"},_vm._l((_vm.question.answers),function(answer,key){return _c('button',{key:key,class:{
        'list-group-item': true,
        'd-flex': true,
        'align-items-center': true,
        'list-group-item-action': true,
        'font-weight-normal': true,
        'active': _vm.selectedAnswer === key
      },on:{"click":function($event){return _vm.selectAnswer(key)}}},[_vm._v(" "+_vm._s(answer.text ? answer.text : answer.value)+" ")])}),0),_c('b-form',{attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitAnswer.apply(null, arguments)}}},[_c('question-footer',{on:{"back":_vm.back,"next":_vm.submitAnswer}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }