<template>
  <div v-if="currentQuestion" class="my-4 questions">

    <div class="kmu-questionaire--top-container">
      <div class="kmu-questionaire--top-container-header">
        <span class="kmu-questionaire--top-container-header-text" v-html="currentQuestion.category" />
      </div>
      <progress-bar />
    </div>

    <div class="questions__container">
      <transition name="fade" mode="out-in" @before-enter="afterEnter">
        <component
          :is="component"
          :key="currentQuestion.id"
          :question="currentQuestion"
          :currentAnswer="currentAnswer"
          @back="back"
          @answer="answer"
        ></component>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import questionComponents from '@/components/QuestionTypes'

import Progressbar from '@/components/Progressbar.vue'
import QuestionSelect from '@/components/QuestionTypes/Select.vue'
import QuestionMultiNumber from '@/components/QuestionTypes/MultiNumber.vue'
import QuestionText from '@/components/QuestionTypes/Text.vue'
import QuestionFinancialGuv from '@/components/QuestionTypes/FinancialDataGuv.vue'
import QuestionFinancialKorrekturen from '@/components/QuestionTypes/FinancialDataKorrekturen.vue'
import QuestionFinancialBilanz from '@/components/QuestionTypes/FinancialDataBilanz.vue'
import QuestionSummary from '@/components/QuestionTypes/Summary.vue'

export default {
  components: {
    'question-select': QuestionSelect,
    'question-multinumber': QuestionMultiNumber,
    'question-text': QuestionText,
    'question-financial-guv': QuestionFinancialGuv,
    'question-financial-korrekturen': QuestionFinancialKorrekturen,
    'question-financial-bilanz': QuestionFinancialBilanz,
    'question-summary': QuestionSummary,
    'progress-bar': Progressbar
  },
  computed: {
    component () {
      const componentName = 'question-' + this.currentQuestion.type

      if (questionComponents[componentName]) {
        return componentName
      }

      return 'question-text'
    },
    ...mapGetters('questions', {
      currentQuestion: 'currentQuestion',
      hasNextQuestion: 'hasNextQuestion',
      hasPreviousQuestion: 'hasPreviousQuestion',
      questionCount: 'questionCount'
    }),
    ...mapState('questions', [
        'answers',
        'currentQuestionIndex'
      ]),
    currentAnswer () {
      return this.answers[this.currentQuestion.id]
    }
  },
  methods: {
    afterEnter () {
      window.scrollTo({
        x: 0, y: 0, bahvior: 'smooth'
      })
    },
    back (value) {
      if (value) {
        this.setAnswer(value)
      }

      if (!this.hasPreviousQuestion) {
        this.$router.push({ name: 'intro' })
        return
      }

      this.showPreviousQuestion()
    },
    answer (value) {
      this.setAnswer(value)

      if (!this.hasNextQuestion) {
        this.setFinished(true)
        this.storeAnswers()
        this.$router.push({ name: 'summary' })
        return
      }

      this.showNextQuestion()
    },
    ...mapActions('questions', {
      setAnswer: 'setAnswer',
      showNextQuestion: 'showNextQuestion',
      showPreviousQuestion: 'showPreviousQuestion',
      storeAnswers: 'storeAnswers'
    }),
    ...mapMutations('questions', {
      setQuestionIndex: 'setQuestionIndex',
      setFinished: 'setFinished'
    })
  },
  watch: {
    "$route.params.index": {
      handler: function(questionIndex) {
        let index = this.currentQuestionIndex
        if (questionIndex) {
          index = parseInt(questionIndex)
        }
        this.setQuestionIndex(index)
      },
      //deep: true,
      immediate: true,
    }
  }
}
</script>

<style lang="scss">
.kmu-questionaire--top-container {
  box-shadow: 0 4px 8px 0 #11161a29,0 4px 8px 0 rgba(17,22,26,.08),0 8px 16px 0 rgba(17,22,26,.04);
  margin-bottom: 2.75rem;
}

.kmu-questionaire--top-container-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e9e9;
  padding: 1rem;
}

.kmu-questionaire--top-container-header-text {
  font-size: 1.6rem;
  font-style: italic;
  font-stretch: expanded;
}
</style>
