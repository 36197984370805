<template>
  <div class="text-muted">
    <h2 class="mt-5 mb-2">Antworten</h2>
    <pre>{{ answers }}</pre>

    <h2 class="mt-5 mb-2">Fragen</h2>
    <pre>{{ questions }}</pre>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('questions', {
      answers: state => state.answers,
      questions: state => state.questions,
      result: state => state.result
    }),
    ...mapGetters('products', {
      filterQuery: 'filterQuery',
      filter: 'filter',
      products: 'products'
    })
  }
}
</script>
