<template>
  <div class="home">
    <div>
      <h2 class="text-center mb-4">Willkommen!</h2>
      <p class="question__description">
        Der nachfolgende Online-Unternehmenswertrechner ist optimiert für kleine und mittlere Unternehmen (KMU). Das Ergebnis wird Ihnen <strong>ohne Registrierung direkt online angezeigt</strong>. Sie haben <strong>zusätzlich</strong> die Möglichkeit, sich einen Kurzübersicht (BASIC-Version) oder einen ausführlichen Bericht (PRO-Version) <strong>bequem per E-Mail</strong> zustellen zu lassen.
      </p>
      <p class="question__description">
        Bitte beachten Sie, dass sich eine Unternehmensbewertung als vielschichtiger Prozess darstellt und i.d.R. charakterisiert ist durch zahlreiche unternehmens- sowie branchenspezifische Besonderheiten und individuelle Sondereffekte.
      </p>
      <p class="question__description">
        Das Bewertungsergebnis aus dem Onlinetool liefert mit einer abschließenden Wertindikation einen ersten Anhaltswert. <strong>Weitere Detailprüfungen durch erfahrene Experten sind dahingehend empfehlenswert.</strong>
      </p>
      <p class="question__notice">
        <em>Hinweis: Für den Sonderfall einer Betriebsaufspaltung sind die Besitz- und die Betriebsgesellschaft entweder getrennt in zwei separaten Bewertungen oder konsolidiert zu erfassen.</em>
      <p>
      <p class="question__notice">
        Bitte planen Sie für die Eingabe der Daten ca. 15-20 Minuten ein. Ihre Daten werden vertraulich behandelt.
      </p>
    </div>

    <div>
      <b-form-checkbox v-model="nutzungsbedingungen" name="nutzungsbedingungen">
        ich akzeptiere die <a href="https://www.kmu-value.de/nutzungsbedingungen.html" target="_blank">Nutzungsbedingungen</a> und habe die Erklärung zum <a href="https://www.kmu-value.de/datenschutz.html" target="_blank">Datenschutz</a> gelesen.
      </b-form-checkbox>
    </div>

  </div>
</template>

<script>
import { mapActions} from 'vuex'

export default {
  name: 'Intro',

  mounted() {
    let questionaireType = this.$route.query.type
    if (!questionaireType) {
      questionaireType = 'BASIC'
    } else {
      questionaireType = questionaireType.toUpperCase()
    }
    this.$store.commit('setQuestionaireType', questionaireType);

    // store caseId - can also be null
    this.$store.commit('setQuestionaireCase', this.$route.query.case);
  },

  methods: {
    ...mapActions('questions', {
      startQuestionnaire: 'startQuestionnaire'
    }),
  },

  computed: {
    nutzungsbedingungen: {
      get: function () {
        return this.$store.state.nutzungsbedingungen;
      },
      set: function (value) {
        this.$store.commit('setNutzungsbedingungen', value);
        // user accepted 'nutzungsbedingungen' so jump into the questionaire
        if (value) {
          this.startQuestionnaire()
          this.$router.push('questionaire')
        }
      }
    }
  }
}
</script>
