<template>
  <div>
    <question-header
      :title="question.title"
      :description="question.description"
      :help="question.help">
    </question-header>

    <div class="list-group mb-4">
      <button
        v-for="answer, key in question.answers"
        :key="key"
        v-on:click="selectAnswer(key)"
        :class="{
          'list-group-item': true,
          'd-flex': true,
          'align-items-center': true,
          'list-group-item-action': true,
          'font-weight-normal': true,
          'active': selectedAnswer === key
        }">
        {{ answer.text ? answer.text : answer.value }}
      </button>
    </div>

    <b-form action="" @submit.prevent="submitAnswer">
      <question-footer
        @back="back"
        @next="submitAnswer"
      />
    </b-form>

  </div>
</template>

<script>
import QuestionType from '@/inc/QuestionType'

import QuestionHeader from '@/components/QuestionHeader.vue'
import QuestionFooter from '@/components/QuestionFooter.vue'

import { mapState } from 'vuex'

export default {
  extends: QuestionType,
  components: {
    QuestionHeader,
    QuestionFooter
  },
  data () {
    return {
      selectedAnswer: null,
      hasAnswer: false
    }
  },
  mounted () {
    if (typeof (this.currentAnswer) === 'undefined') {
      return
    }

    const answerIndex = this.question.answers.findIndex(answer => {
      return answer.value === this.currentAnswer
    })

    if (answerIndex !== -1) {
      this.setAnswer(answerIndex)
    }
  },
  computed: {
    ...mapState('questions', {
      questionaireFinished: state => state.finished
    }),
    answerValue () {
      const answer = this.question.answers[this.selectedAnswer]
      return answer?.value
    }
  },
  methods: {
    selectAnswer (answerKey) {
      this.setAnswer(answerKey)

      // do only autosubmit if questionaire ist not
      // finished as we are in single-edit-mode otherweise
      if (!this.questionaireFinished) {
        setTimeout(() => {
          this.submitAnswer()
        }, 200)
      }
    },
    setAnswer (answerKey) {
      this.hasAnswer = true
      this.selectedAnswer = answerKey
    },
    submitAnswer () {
      if (!this.hasAnswer) {
        return
      }

      this.answer(this.answerValue)
    }
  }
}
</script>
