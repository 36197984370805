import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

import VueMeta from 'vue-meta'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'

import App from './App.vue'
import router from './router'
import store from './store'
import questions from './config/questions.json'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import './scss/app.scss'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueI18n)
Vue.use(VueMeta)
Vue.use(VueTheMask)
Vue.use(Vuelidate)

// load questions into store
store.dispatch('questions/loadQuestions', questions)

const numberFormats = {
  'de-DE': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0
    },
    money: {
      style: 'decimal',
      maximumFractionDigits: 0
    },
    percent: {
      style: 'decimal',
      maximumFractionDigits: 1
    },
    percent2: {
      style: 'decimal',
      maximumFractionDigits: 2
    },
    money_fraction: {
      style: 'decimal',
      maximumFractionDigits: 2
    },
  }
}

const i18n = new VueI18n({
  locale: 'de',
  numberFormats
})

Sentry.init({
  Vue,
  dsn: "https://3ad66e03a2a74b649bbb9161141d814c@o4504293714821120.ingest.sentry.io/4504293725110272",
  environment: "basic",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "kmu-value.de/", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false

const main = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default main;
