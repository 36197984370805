<template>
  <div>
    <b-table-lite hover small :fields="columns" :items="answerDetails">
      <template #head(actions)>
        <b-icon icon="gear"></b-icon>
      </template>
      <template #cell(answervalue)="data">
        <div v-if="data.item.type === 'multinumber'">
          {{ $n(data.item.answervalue.entwicklung_1, 'percent', 'de-DE') }} % &#x2192; {{ $n(data.item.answervalue.entwicklung_2, 'percent', 'de-DE') }} % &#x2192; {{ $n(data.item.answervalue.entwicklung_3, 'percent', 'de-DE') }} % <span  class="text-muted">(Planjahr 1, 2, 3)</span>
        </div>
        <div v-else-if="!(data.item.type === 'multinumber') && data.item.formatter">{{ $n(data.item.answervalue, data.item.formatter, 'de-DE') }}</div>
        <div v-else>{{ data.item.answervalue }}</div>
      </template>
      <template #cell(actions)="data">
        <a class="summaryDetails-show-when-hovered" href="#"
          @click="setQuestionIndexByQuestionId(data.item.questionid)">
          <b-icon icon="pencil-fill"></b-icon>
        </a>
      </template>
    </b-table-lite>

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    questionIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          key: 'questiontitle',
          label: 'Unsere Frage'
        },
        {
          key: 'answervalue',
          label: 'Ihre Antwort',
          thClass: 'summaryDetails-header-columnAnswer',
          tdClass: 'summaryDetails-columnAnswer'
        },
        {
          key: 'actions',
          thClass: 'summaryDetails-header-columnAction',
          tdClass: 'summaryDetails-columnAction'
        },
      ]
    }
  },
  methods: {
    ...mapMutations('questions', {
      setQuestionIndexByQuestionId: 'setQuestionIndexByQuestionId',
    })
  },
  computed: {
    ...mapState('questions', {
      questions: state => state.questions,
      answers: state => state.answers
    }),
    answerDetails() {
      let result = []
      this.questionIds.forEach(questionId => {
        let question = this.questions.find( question => {
          return question.id === questionId
        })
        let answer = this.answers[questionId]
        if (question) {
          result.push({
            questionid: questionId,
            type: question.type,
            formatter: question.formatter,
            questiontitle: question.title,
            answervalue: answer ? answer : '-'
          })
        }
      })
      return result;
    },
  }
}
</script>

<style>
.summaryDetails-header-columnAnswer {
  text-align: right;
}
.summaryDetails-columnAnswer {
  text-align: right;
  width: 20rem;
}
.summaryDetails-header-columnAction {
  text-align: center;
}
.summaryDetails-columnAction {
  text-align: center;
  width: 3rem;
}
@media (pointer:fine) {
    .b-table > tbody > tr:not(:hover) .summaryDetails-show-when-hovered {
      display: none;
    }
}
</style>
