<template>
  <b-form-group
    :state="s && v.$invalid ? false : null"
    :invalid-feedback="invalidFeedback"
    >
    <b-form-input
      :id="id"
      ref="input"
      type="text"
      class="text-right"
      :state="s && v.$invalid ? false : null"
      v-model="displayValue"
      @blur="setEditorActive(false)"
      @focus="setEditorActive(true)"
      @focus.native="$event.target.select()"
    />
  </b-form-group>
</template>

<script>

export default {
  props: {
    id: {
      type: String
    },
    value: {
      required: true
    },
    s: {
      type: Boolean,
      default: true
    },
    v: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
        isInputActive: false
    }
  },
  methods: {
    setEditorActive(value) {
      this.isInputActive = value
    }
  },
  computed: {
    displayValue: {
      get() {
        if (this.isInputActive) {
          return this.value.toString()
        } else {
          return ' ' + this.$n(this.value, 'money', 'de-DE')
        }
      },
      set(modifiedValue) {
        let shouldNegative = false
        if (modifiedValue.includes('-') || this.displayValue.includes('-')) {
          shouldNegative = true
        }

        // normalise the input string by replacing all but digits and a ','
        let newValue = modifiedValue.replace(/[^\d,]/g, '')
          newValue = newValue.replace(/,/g, '.')
          newValue = parseFloat(newValue)

        if (isNaN(newValue)) {
          newValue = 0
        } else {
          newValue = Math.round(newValue)
        }

        if (shouldNegative) {
          newValue = newValue * -1
        }

        this.$emit('input', newValue)
        this.v.$touch()
      }
    },
    invalidFeedback() {
      if (this.v?.korrekturWennAufwand === false) {
        return "Gehalt ist kleiner als Personalaufwand!"
      }
      if (this.v?.korrekturGroesserAlsAufwand === false) {
        return "Gehalt ist größer als Personalaufwand!"
      }
      if (this.v?.minGehalt === false) {
        return "Gehalt muss größer als 50T€ sein!"
      }
      if (this.v?.summeAktivaPassiva === false) {
        return "Summe Aktiva und Passiva müssen gleich sein!"
      }

      return null
    }
  }
}
</script>
