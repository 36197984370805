export default {
  emits: [
    'answer',
    'back'
  ],
  props: {
    question: {
      type: Object,
      required: true
    },
    currentAnswer: undefined
  },
  methods: {
    back (value) {
      this.$emit('back', value)
    },
    answer (value) {
      this.$emit('answer', value)
    }
  }
}
